import React, { Component } from 'react';
import { getScopedI18n, getSurveyLanguage } from "core/i18n";
import { ANZ_InputChoiceQuestion } from './ANZ_InputChoiceQuestion';

const ta = getScopedI18n('survey_assessment.answers', { locale: getSurveyLanguage() });

interface Props {
    title: string;
    answerIndex: number;
    questionIndex: number;
    answers: string[];
    answerHandler(answer: any, question: number): void;
    className: string;
    otherField: string;
    key: string;
    validateIsClickPreviousQuestion(index: number): void;
}

interface State {
    isSelectedOther: boolean;
    otherText: string;
    answer: string;
}

class SingleChoiceQuestionWithOpenText extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            isSelectedOther: false,
            otherText: '',
            answer: '',
        }
    }

    resetButtons = () => {
        let fieldToSelect = document.getElementsByClassName(`question_${this.props.otherField}`)[0]
        fieldToSelect.classList.remove('btn-primary')
        fieldToSelect.classList.add('btn-light')
        this.setState({ otherText: '', answer: '', isSelectedOther: false })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.selectedAnswer && !this.props.selectedAnswer) {
            // focus on text field;
            this.resetButtons();
        }
    }

    handleOnClickAnswer = (fieldName, questionIndex) => {
        //unselect prev
        if (this.state.answer) {
            let previousAnswer = document.getElementsByClassName(`question_${this.state.answer}`)
            previousAnswer[0].classList.remove('btn-primary');
            previousAnswer[0].classList.add('btn-light');
        }

        this.setState({
            answer: fieldName,
        }, () => {
            if (fieldName === this.props.otherField) {
                this.props.validateIsClickPreviousQuestion(questionIndex)
                let other = document.getElementsByClassName(`question_${fieldName}`)
                other[0].classList.remove('btn-light');
                other[0].classList.add("btn-primary");
                this.setState({
                    isSelectedOther: true,
                })
            } else {
                this.setState({
                    isSelectedOther: false,
                    otherText: ''
                }, () => {
                    this.props.answerHandler(fieldName, questionIndex)
                })
            }
        })

    }

    render() {
        const { className, title, answers, answerIndex, questionIndex, otherField, key } = this.props;
        return (
            <div className={`${className}-other`}>
                <div className="hs-form">
                    <h5 className="question">
                        <span>{title}</span>
                    </h5>
                    <div className="answer-choices diagnosis-answer-choices">
                        {answers.map((ele, index) => {
                            if (answerIndex >= 0 && index === answerIndex) {
                                return (
                                    <button
                                        type="button"
                                        onClick={() => this.handleOnClickAnswer(ele, questionIndex)}
                                        className={`btn btn-lg btn-primary answer${otherField === ele ? '-other' : '-next'} question_${ele}`}
                                        key={index}
                                    >
                                        {ta(ele)}
                                    </button>
                                );
                            } else {
                                return (
                                    <button
                                        type="button"
                                        onClick={() => this.handleOnClickAnswer(ele, questionIndex)}
                                        className={`btn btn-lg btn-light other-field-next-button answer${otherField === ele ? '-other' : '-next'} question_${ele}`}
                                        key={index}
                                    >
                                        {ta(ele)}
                                    </button>
                                );
                            }
                        })}
                    </div>
                    {this.state.isSelectedOther ? <>
                        <div>
                            <ANZ_InputChoiceQuestion
                                rootLevelClass={`${className} question_${otherField}`}
                                key={key}
                                questionIndex={questionIndex}
                                title={title}
                                onChange={(e) => this.setState({ otherText: e })}
                            />
                        </div>
                        <button
                            id="other-field-next-button"
                            type="button"
                            onClick={() => this.props.answerHandler({ [otherField]: this.state.otherText }, questionIndex)}
                            className="btn btn-lg btn-primary other-field-next-button"
                            style={{
                                display: 'flex',
                                marginLeft: 'auto',
                                marginTop: '1rem'
                            }}
                        >
                            Next
                        </button>
                    </> : ''}

                </div>
            </div>
        );
    }

};

export default SingleChoiceQuestionWithOpenText;