import React from 'react';
import { Container } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import * as SurveyReact from 'survey-react';
import cn from 'classnames';

import { getSurvey, postResponse } from 'api/surveys.api';
import { getBrowserLanguage } from 'core/i18n';
import { captureException, logScreen } from 'core/analytics';

type Props = RouteComponentProps<{ id: id }>;

class SurveyEntity extends React.Component {
  state = {
    survey: null,
    loading: true,
    showCustomContainer: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.survey._id !== (prevProps.survey && prevProps.survey._id)) {
      const surveyId = this.props.survey.survey;
      logScreen('SURVEY', { survey: surveyId });
      if (surveyId) {
        getSurvey(surveyId)
          .then(survey => {
            this.setState({ survey, showCustomContainer: true, loading: false });
          })
          .finally(() => {
            this.setState({ loading: false, showCustomContainer: true });
          });
      } else {
        this.setState({ loading: false, showCustomContainer: true });
      }
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const surveyId = this.props.survey.survey;
    logScreen('SURVEY', { survey: surveyId });
    if (surveyId) {
      getSurvey(surveyId)
        .then(survey => {
          this.setState({ survey });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }

  getLocale() {
    const { location } = this.props;
    const search = new URLSearchParams(location.search);
    const locale = search.get('locale');
    if (locale && ['en', 'nl'].includes(locale)) {
      return locale;
    } else {
      getBrowserLanguage();
    }
  }

  manipulateString = (text, isSpaced) => {
    if (text) {
      text = text.split('\\n');

      return text.map((t, i) => {
        return (
          <div key={i} className={isSpaced ? 'mb10' : ''}>
            {t}
          </div>
        );
      });
    }
  };

  handleSurveySubmit = (srSurvey, survey, currentSurvey) => {
    this.setState(
      {
        showCustomContainer: false,
        loading: true,
      },
      () => {
        this.props.handleSubmitAndPopSurvey(srSurvey, survey, currentSurvey);
      },
    );
  };
  render() {
    const { survey } = this.state;

    const { match, currentSurvey } = this.props;
    const { uri } = this.props;
    const search = new URLSearchParams(uri);
    const test = search.get('test');
    const unit = search.get('unit');
    const recording = search.get('recording');
    const locale = search.get('locale');
    const service = (search.get('service') && search.get('service').toLowerCase()) || null;

    if (service !== 'sisu') {
      SurveyReact.StylesManager.applyTheme('bootstrap');
    }
    const { showCustomContainer, loading } = this.state;
    return (
      <>
        {loading ? (
          <div className="loading-owl">
            <div className="nightowl-img-container">
              <img src={require('../../../assets/images/nightowl_logo.svg')} alt="nightowl-logo" />
            </div>
          </div>
        ) : (
          <>
            {survey ? (
              <Container className={cn({ 'sisu-theme': service === 'sisu' ? true : false })} style={{ padding: 0 }}>
                {service === 'sisu' && this.state.showCustomContainer && (
                  <div className="sisu-main-container">
                    <div className="sisu-header-container">
                      <div className="header-container">
                        <div className="img-container">
                          <img className="" src={require('../../../assets/images/survey-icon.svg')} alt="phone" />
                        </div>
                        <div className="name">{survey.name}</div>
                        <div className="description">{survey.description}</div>
                        <div className="flexBoxSpaceBetween">
                          <div className="disclaimer">{this.manipulateString(survey.disclaimer, false)}</div>
                          <img className="disclaimer-icon" src={require('../../../assets/images/i-icon.svg')} alt="info" />
                        </div>
                      </div>
                    </div>
                    {survey.info && survey.info.content && (
                      <div className="sisu-sub-header-container">
                        <div className="sub-header-container">
                          <img className="icon" src={require('../../../assets/images/question-icon.svg')} alt="question" />
                          <div className="disclaimer">{this.manipulateString(survey.info.content, true)}</div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <SurveyReact.Survey
                  json={survey.survey}
                  onComplete={srSurvey => this.handleSurveySubmit(srSurvey, survey, currentSurvey)}
                  locale={this.getLocale()}
                />
              </Container>
            ) : null}
          </>
        )}
      </>
    );
  }
}

class MultiSurveysClass extends React.Component<Props, {}> {
  state = {
    survey: null,
    loading: true,
    currentSurvey: null,
    surveys: [],
  };

  componentDidMount() {
    const { location } = this.props;
    const search = new URLSearchParams(decodeURIComponent(location.search));
    let surveys = JSON.parse(search.get('surveys'));
    this.setState({
      currentSurvey: surveys[0],
      surveys: surveys,
    });
  }

  handleSubmitAndPopSurvey = (survey, ownSurvey, currentSurvey) => {
    this.sendDataToServer(survey, ownSurvey, currentSurvey);
  };

  sendDataToServer = (survey: any, ownSurvey: any, currentSurvey) => {
    const { location } = this.props;
    const search = new URLSearchParams(location.search);
    const test = search.get('test');
    let recording = null;
    const unit = search.get('unit');

    if (currentSurvey.position === 'PRE_RECORDING' || currentSurvey.position === 'POST_RECORDING') {
      recording = search.get('recording');
    }

    return postResponse({
      survey: ownSurvey._id,
      response: survey.data,
      unit,
      test,
      recording,
    }).finally(() => {
      // Navigate to finished url
      let filteredSurveys = this.state.surveys.filter(item => {
        return item.survey !== ownSurvey._id;
      });
      if (this.state.surveys.length === 1) {
        window.location.replace('/finish');
      } else {
        this.setState({
          surveys: filteredSurveys,
          currentSurvey: filteredSurveys[0],
        });
      }
    });
  };

  render() {
    const { currentSurvey } = this.state;
    if (!currentSurvey) {
      return null;
    }
    return (
      <div>
        <SurveyEntity
          {...this.state}
          {...this.props}
          handleSubmitAndPopSurvey={this.handleSubmitAndPopSurvey}
          survey={currentSurvey}
          uri={this.props.location.search}
        />
      </div>
    );
  }
}

export const MultiSurveys = withRouter(MultiSurveysClass);
