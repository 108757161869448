import React, { useState } from 'react';

export const InputChoiceQuestion = props => {
  const [disable, setDisable] = useState(true);
  const onChange = e => {
    if (
      document.getElementById('height') &&
      document.getElementById('height').value.length > 0 &&
      document.getElementById('weight') &&
      document.getElementById('weight').value.length > 0
    ) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };
  return (
    <div className={props.rootLevelClass}>
      <div className="hs-form">
        <h5 className="question">
          <span>{props.title}</span>
        </h5>
        <div className="answer-choices diagnosis-answer-choices cst_no_input">
          <div className="cst_input">
            <input
              id="height"
              min="1"
              max="500"
              pattern="[0-9]*"
              type="number"
              onChange={e => onChange(e)}
              className="input_number width_half modify_control"
              placeholder="0 cm"
              name="sScreener.height"
            />
            <input
              id="weight"
              type="number"
              min="1"
              max="500"
              pattern="[0-9]*"
              onChange={e => onChange(e)}
              className="input_number width_half modify_control"
              placeholder="0 kg"
              name="sScreener.height"
            />
          </div>
          <button
            type="button"
            disabled={disable}
            className="btn btn-lg btn-primary answer"
            onClick={() =>
              props.answerHandler([document.getElementById('height').value, document.getElementById('weight').value], props.questionIndex)
            }
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
