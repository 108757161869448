import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Survey } from "./components/Survey";
import { MultiSurveys } from "./components/MultiSurveys";
import NOSurvey from "./assessment";
import { SurveyFinished } from "./components/SurveyFinished";

import "./styles.scss";

export const SurveyContainer = () => (
  <div className="App">
    <Switch>
      <Route exact path="/survey/finish" component={SurveyFinished} />
      <Route path="/survey/assessment" component={NOSurvey} />
      <Route
        path="/survey/5de678751c9d44000007f921"
        render={props => <Redirect to={{ pathname: "/assessment", search: props.location.search }} />}
      />
      <Route
        path="/survey/643d222e05c28d55d6b4c761"
        render={props => <Redirect to={{ pathname: "/assessment", search: props.location.search, state: { surveyId: '643d222e05c28d55d6b4c761' } }} />}
      />
      <Route path="/survey/:id" component={Survey} />
      <Route path="/surveys" component={MultiSurveys} />
    </Switch>
  </div>
);

export default SurveyContainer;
