import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import { RouteConstants } from 'containers/Survey/routes';
import {
  OSA_RISK_TYPE,
  OSARiskMetaData,
  InsomniaMetadata,
  RESULT_TYPE,
  SnoringMetadata,
  SleepHealthMetadata,
  SortResults,
  INSOMNIA_RISK_TYPE,
} from '../constants/constants';
import { ResultCard } from '../components/Result/ResultCard';
import { addCardToggleScript, addTabScript } from '../styles/custom';
import { logScreen } from 'core/analytics';

interface Props extends RouteComponentProps {}

interface State {
  user: any;
}

class Result extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      user: props.location.state ? props.location.state.user : null,
    };
  }

  componentWillMount = () => {
    if (!this.props.location.state) {
      this.props.history.push(RouteConstants.ASSESSMENT_ROOT);
    }
  };

  componentDidMount = () => {
    logScreen('ASSESSMENT_RESULTS');
    addCardToggleScript();
    addTabScript();
  };

  render() {
    const { user } = this.state;
    let riskType = OSA_RISK_TYPE.OSAHighRisk;
    let snoring_risks = null;
    let insomnia_scoring = null;
    let physiologicalSignsRisks = null;
    let sleep_health_recommendations = null;
    const { questions } = this.props.location.state.user;
    const isDiagnoisedCOPDHeartFailure = questions.filter(ele => ele.request_key === 'copd')[0].selected_answer === 'yes';
    if (user) {
      riskType = user.riskType;
      physiologicalSignsRisks = user.physiologicalSignsRisks;
      insomnia_scoring = user.insomnia_scoring;
      snoring_risks = user.snoring_risks;
      sleep_health_recommendations = user.sleep_health_recommendations;
    }

    let risks = [];

    // OSA risk
    if (riskType) {
      // COPD_heart_failure_Opioids
      const { severity_title } = OSARiskMetaData(riskType, isDiagnoisedCOPDHeartFailure);
      risks.push({
        isDiagnoisedCOPDHeartFailure,
        type: RESULT_TYPE.sleep_apnea,
        title: 'Sleep apnea',
        severityTitle: (
          <p className="theme_para">
            Your symptoms and BMI suggests that you may be at <strong>{severity_title} of Sleep Apnea.</strong>
          </p>
        ),
        physiologicalSignsRisks,
        question: 'What is Sleep Apnea?',
        ...OSARiskMetaData(riskType, isDiagnoisedCOPDHeartFailure),
      });
    }

    // Insomnia
    if (insomnia_scoring) {
      const [insomniaScore, pains, insomniaSeverityType] = insomnia_scoring;
      const { severity_title, indicator_severity } = InsomniaMetadata(insomniaSeverityType, pains);
      risks.push({
        type: RESULT_TYPE.insomnia,
        title: 'Insomnia',
        severityTitle: (
          <p className="theme_para">
            Your assessment results suggest you may be at <strong>{severity_title}</strong>.
          </p>
        ),
        question: 'What is Insomnia?',
        ...InsomniaMetadata(insomniaSeverityType, pains),
      });
      risks = SortResults(risks, pains);
    }

    // Snoring
    if (snoring_risks && snoring_risks.length > 0) {
      // getting array of snoring data, since we need to show partner if it is evaluated along with any other snoring
      const snoringData = SnoringMetadata(snoring_risks);
      const data = snoringData[0];
      const { severity_title } = data;
      risks.push({
        type: RESULT_TYPE.snoring,
        severityTitle: snoringData[0].subTitle ? (
          snoringData[0].subTitle
        ) : (
          <p className="theme_para">
            Your assessment results suggest you may be a <strong>{severity_title}</strong>.
          </p>
        ),
        ...snoringData[0],
      });
    }

    // Sleephealth
    if (sleep_health_recommendations && sleep_health_recommendations.length > 0) {
      const { severity_title } = SleepHealthMetadata(sleep_health_recommendations);
      risks.push({
        type: RESULT_TYPE.sleep_health,
        severityTitle: (
          <p className="theme_para">
            From your assessment results the following symptoms suggest you may benefit from <strong>Sleep Health products</strong>.
          </p>
        ),
        sleepHealthRecommendations: sleep_health_recommendations,
        question: `What is Sleep Health?`,
        icon: '/images/icon/Icon_Sleep health.svg',
        ...SleepHealthMetadata(sleep_health_recommendations),
      });
    }

    return (
      <div className="container assessment_results">
        <article className="intro">
          <h2 className="theme_title1">Sleep Assessment Results</h2>
        </article>
        <div className="row">
          {risks.map((risk, index) => (
            <ResultCard {...risk} />
          ))}
        </div>
        {/*<div className='theme_app_section result_tab_sec result_sec'>*/}
        {/*  <div className='theme_app_container'>*/}
        {/*    <div className='app_inner_container result_tab_container'>*/}
        {/*      <div className='app_content_block result_tab_con'>*/}
        {/*        <div className='tab-slider--container'>*/}
        {/*          <div className='results_tab'>*/}
        {/*            {risks.map((risk, index) => (*/}
        {/*                <ResultCard {...risk} />*/}
        {/*              )*/}
        {/*            )}*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className='btn_block text_center'>*/}
        {/*        <a href='https://www.resmed.com.au/' className='btn_link' target='blank'>Go back to ResMed.com</a>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default Result;
