import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import api from 'api';
import { RouteConstants } from 'containers/Survey/routes';
import { logScreen } from 'core/analytics';
import { UserForm } from '../components/UserDetail/UserForm';

import '../styles/styles.scss';
import { UserForm_ANZ } from '../components/UserDetail_ANZ/UserForm_ANZ';

interface Props extends RouteComponentProps {
  surveyId: string;
}

interface State {
  submitting: boolean;
}

class Home extends Component<Props, State> {
  constructor(props) {
    super(props);
    console.log(`home props`)
    console.log(props);
    this.state = {
      submitting: false,
    };
  }

  componentDidMount(): void {
    logScreen('ASSESSMENT_HOME');
  }

  submitFormHandler = async values => {
    this.setState({
      submitting: true,
    });

    try {
      const patient = await api.surveys.createSurveyPatient(values);
      const search = new URLSearchParams(this.props.location.search);
      search.set('patient', patient._id);
      let pathName = this.props && this.props.location && this.props.location.state && this.props.location.state.surveyId == '643d222e05c28d55d6b4c761' ? RouteConstants.ASSESSMENT_SURVEY_ANZ : RouteConstants.ASSESSMENT_SURVEY
      this.props.history.push({
        pathname: pathName,
        search: search.toString(),
      });
    } catch (e) {
      console.error(e);
    }

    this.setState({
      submitting: false,
    });
  };

  renderForm = () => {
    if (this.props && this.props.location && this.props.location.state && this.props.location.state.surveyId == '643d222e05c28d55d6b4c761') {
      return <UserForm_ANZ submitting={this.state.submitting} onSubmit={this.submitFormHandler} />
    }
    return <UserForm submitting={this.state.submitting} onSubmit={this.submitFormHandler} />
  }

  render() {
    return (
      <div className="container assessment_home">
        <article className="intro">
          <h1 className="theme_title1">Sleep Assessment</h1>
        </article>
        {this.renderForm()}
      </div>
    );
  }
}

export default Home;
