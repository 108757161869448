import React, { useState } from 'react';
import Select from 'react-select';

import { reactSelectTheme } from 'core/theme';
import { Form, FormControlProps } from 'react-bootstrap';
import cn from 'classnames';

interface Props extends FormControlProps {
    options?: Array<Object>;
    label?: string,
    //value?: any;
    //required?: boolean;
    //isInvalid: boolean;
    //label?: string;
}

interface State {
    selectedOption: Object;
}

export const BasicDropdown: React.Component<Props> = ({ label, asCol, groupAs, required, options, ...props }: Props) => {

    const [selectedOption, setSelectedOption] = useState({});

    const handleChange = (e) => {
        setSelectedOption(e);
    }

    return <div>
        <Form.Group {...{ as: asCol ? Col : groupAs ? groupAs : undefined }}>
            {label && (
                <Form.Label>
                    {label}
                    {required ? '*' : null}
                </Form.Label>
            )}
            <Select
                className={cn('gender-input-dropdown')}
                styles={{
                    control: (provided, state) => ({
                        ...provided,
                        color: '#495057',
                        backgroundColor: '#fff',
                        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(88, 199, 180, .25)' : 'unset',
                        borderColor: state.isFocused ? '#b7e7df' : '#ced4da',
                        '&:hover': {
                            borderColor: state.isFocused ? '#b7e7df' : '#ced4da',
                        }
                    }),

                }}
                theme={reactSelectTheme}
                value={selectedOption}
                options={options}
                onChange={(data: any) => handleChange(data)}
            />
        </Form.Group >

    </div >

}