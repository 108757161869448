/* eslint-disable max-len */
/* tslint:disable */
export default {
  input_labels: {
    email: 'Email',
    phone: 'GSM',
    password: 'Wachtwoord',
    password_confirm: 'Bevestig wachtwoord',
    password_new: 'Nieuw wachtwoord',
    password_old: 'Oud wachtwoord',
    simple: 'Simpel',
    select_unit: 'Selecteer Center',
    unit: 'Center',
    lastname: 'Naam',
    firstname: 'Voornaam',
    birthday: 'Geboortedatum',
    birthday_and_format: 'Geboortedatum (YYYY-MM-DD)',
    language: 'Taal',
    fulfillment: 'Fulfillment by Ectosense',
    address: 'Adres',
    city: 'Gemeente',
    region: 'Staat/Provincie/Regio',
    zip: 'Postcode',
    country: 'Land',
    offline: 'Offline test',
    nights: 'Nachten',
  },
  survey_assessment: {
    finish: {
      title: 'Bedankt',
      subtitle: 'De vragenlijst werd succesvol ingevuld. Uw arts zal de antwoorden evalueren en de gepaste opvolgacties ondernemen.',
      button: 'Verder',
    },
    questions: {
      satisfaction: 'Hoe tevreden ben je over jouw huidige slaappatroon?',
      sleep_description: 'Hoe zou je je slaap omschrijven (kies het meest geschikte)?',
      sleep_hours: 'Hoeveel uur slaap je elke nacht gemiddeld?',
      sleep_related_pain: 'Hoe vaak heb je problemen gehad met slapen door pijn?',
      feel_sleepy: 'Zelfs na de hele nacht geslapen te hebben, voel je je dan nog slaperig overdag?',
      teeth_grinding: 'Word je wakker met een pijnlijke kaak? Of ben je ooit verteld dat je knarsetandt tijdens het slapen?',
      difficult_falling_asleep: 'Vind je het moeilijk om in slaap te vallen? Hoe erg is dit probleem voor jou?',
      difficult_staying_asleep: 'Vind je het moeilijk om in slaap te blijven? Hoe erg is dit probleem voor jou?',
      waking_early:
        'Heb je problemen met vroeg wakker worden, en moeite om nadien terug in slaap te vallen? Hoe erg is dit probleem voor jou?',
      negative_impact: 'Hoe zeer beïnvloedt je slaap jouw levenskwaliteit? (e.g. vermoeidheid, stemming, werkprestaties, concentratie,...)',
      sleep_worried: 'Hoe bezorgd ben je over je huidige slaappatroon?',
      snore_self: 'Laten we nu eens kijken naar jouw nachtelijke ademhaling. Ben je wel eens verteld dat je snurkt?',
      snore_position: 'Kan je je herinneren in welke positie je snurkt?',
      dry_mouth: 'Word je wakker met een droge mond?',
      snore_partner: 'Slaap je naast iemand die snurkt?',
      gasping_breath: 'Word je wel eens wakker al happend naar adem?',
      hold_breath: 'Is je ooit verteld dat je jouw adem inhoudt tijdens het slapen?',
      headaches: 'Word je wakker met hoofdpijn in de ochtend?',
      heartburn: 'Ervaar je soms klachten van maagzuur of zure oprispingen, of neem je medicatie om het te behandelen?',
      blood_pressure: 'Heb je een hoge bloeddruk of neem je medicatie tegen een hoge bloeddruk?',
      restless_legs: 'Heb je soms het gevoel dat je jouw benen moet bewegen om ze comfortabel te laten voelen?',
      disorder_medication: 'Heb je last van een depressie of stemmingsstoornis en neem je hier medicatie voor?',
      copd:
        'Heeft u ooit de diagnose van COPD (chronisch obstructieve longziekte) of hartfalen gekregen, of gebruikt u opioïden om chronische pijn te behandelen? ',
      bmi:
        'Je BMI en leeftijd kans ons jouw risico factor voor bepaalde slaapandoeningen helpen begrijpen. Laten we je BMI berekenen. Vul je lengte en gewicht in.',
      age: 'Wat is je leeftijdscategorie?',
      // eureka: 'Was er een 'Eureka/aha!' moment of voorval dat je motiveerde om je slaap gezondheid te verbeteren?',
      motivation: 'Wat is je motivatie om  je slaap te verbeteren?',
      gender: 'Ten slotte, wat beschrijft je geslacht het beste?',
    },
    answers: {
      yes: 'Ja',
      no: 'Nee',
      not_sure: 'Niet zeker',
      very_satisfied: 'Erg tevreden',
      satisfied: 'Tevreden',
      moderately_satisfied: 'Matig tevreden',
      dissatisfied: 'Ontevreden',
      very_dissatisfied: 'Zeer ontevreden',
      deep: 'Diep',
      great: 'Geweldig',
      terrible: 'Verschrikkelijk',
      less_5_hours: 'Minder dan 5 uur',
      '5_to_7_hours': '5 tot 7 uur',
      '7_to_9_hours': '7 tot 9 uur',
      more_9_hours: 'Meer dan 9 uur',
      never: 'Nooit',
      less_once_week: 'Minder dan één keer per week',
      once_twice_week: 'Eén of twee keer per week',
      three_more_week: 'Drie of meer keer per week',
      no_problem: 'Geen problemen',
      few_issues: 'Weinig problemen',
      moderately: 'Redelijk',
      severe: 'Ernstig',
      very_severe: 'Zeer ernstig',
      none: 'Geen',
      little: 'Weinig',
      somewhat: 'Enigszins',
      alot: 'Erg',
      not_at_all: 'Helemaal niet',
      on_back: 'Op mijn rug',
      on_side: 'Op mijn zij',
      any_position: 'In om het even welke positie',
      no_recall: 'Kan me niet herinneren',
      loved_one: 'Op aanraden van een partner',
      doctor: 'Aanbeveling van mijn dokter',
      energy: 'Weinig energie en vermoeidheid',
      memory: 'Geheugen- en prestatieproblemen',
      general: 'Algemene bezorgdheid over gezondheidsproblemen',
      other: 'Andere',
      male: 'Man',
      female: 'Vrouw',
      light: 'Licht',
      fragmented: 'Gefragmenteerd',
      interrupted: 'Kan beter',
    },
    sleep_health: {
      sleep_health1: {
        light: 'Lichte slaper',
        interrupted: 'Onderbroken slaper',
        fragmented: 'Gefragmenteerde slaper',
      },
      sleep_health2: {
        yes: 'Maagzuur/Zure oprispingen',
      },
      sleep_health3: {
        yes: 'Rusteloze benen',
      },
    },
    physio_signs: {
      snoring: 'Snurken',
      hold_breath: 'Adem inhouden',
      gasping: 'Snakken naar adem',
      sleepy: 'Slaperigheid overdag',
      dry_mouth: 'Droge mond/Pijnlijke keel',
      heartburn: 'Maagzuur/Zure oprispingen',
      blood_pressure: 'Hoge bloeddruk',
      headaches: 'Hoofdpijn',
    },
    snoring_type: {
      positional: 'Positioneel snurken',
      mouth: 'Mond snurken',
      nose: 'Neus snurken',
      partner: 'Partner snurkt',
    },
    result: {
      patient: {
        title: 'Patiënt',
        name: 'Naam',
        birthday: 'Geboortedatum',
      },
      evaluation: {
        title: 'Evaluatie',
      },
      indicator: 'Risico-indicator:',
      your_indicators: 'Uw indicatoren:',
      your_physio_indicators: 'Uw physiologische indicatoren:',
      recommendations: 'Aanbevelingen',
      high_risk: 'hoog risico',
      moderate_risk: 'matig risico',
      low_risk: 'laag risico',
      at_risk: 'risico',
      at_risk_with_pain: 'risico op slapeloosheid met pijn',
      apnea: {
        title: 'Slaapapneu',
        subtitle: 'Uw symptomen en BMI suggereren dat u misschien een {{severity}} heeft op slaapapneu.',
        question: 'Wat is slaapapneu?',
        recommendations: {
          risk:
            'De resultaten van je slaapbeoordeling geven aan dat je risico loopt op slaapapneu. Overweeg om een slaaptest uit te voeren om te onderzoeken of je behandeling nodig hebt, of raadpleeg uw arts. Als je verfrist en energiek wakker wordt, kun je meer genieten van de dingen waar je van houdt.',
          low_risk:
            'De resultaten van je slaapbeoordeling geven aan dat je een laag risico loopt op slaap apneu. Als je niet zeker bent over je symptomen, kan je overwegen om een slaaptest uit te voeren. Je kan ook met je arts je resultaten bespreken.',
          copd:
            'De resultaten van je slaapbeoordeling geven aan dat je vanwege bestaande medische aandoeningen met je arts moet praten over je symptomen en mogelijke behandelingsopties om je slaap te verbeteren.',
        },
        definition:
          'Slaapapneu is een slaapstoornis waarbij u stopt met ademen terwijl u slaapt. U stopt gedurende 10 seconden of langer met ademen, wat herhaaldelijk tijdens de nacht kan gebeuren. Als dit niet gediagnosticeerd en behandeld wordt, kan dit op lange termijn gevolgen hebben voor uw gezondheid. Een thuis-slaapstudie zal helpen bepalen of u een slaapstoornis heeft.',
      },
      insomnia: {
        title: 'Slapeloosheid',
        risk_title: '{{risk}} op slapeloosheid',
        risk_pain_title: '{{risk}} op slapeloosheid met pijn',
        subtitle: 'Uw vragenlijst-resultaten suggeren dat u een {{severity}} heeft op slapeloosheid.',
        question: 'Wat is slapeloosheid?',
        recommendations: {
          none: 'Het lijkt erop dat slapeloosheid geen probleem voor je is.',
          normal:
            'De resultaten van je slaapbeoordeling geven aan dat je risico loopt op slapeloosheid (insomnie). Je verdient het om je op je best te voelen met de energie om elke dag optimaal te leven. Je bespreekt dit best met je arts.',
          pain:
            'De resultaten van je slaapbeoordeling geven aan dat je risico loopt op slapeloosheid (insomnie) als gevolg van pijn. Je verdient het om je op je best te voelen met de energie om elke dag optimaal te leven. Je bespreekt dit best met je arts en mogelijks een slaappsycholoog.',
        },
        definition:
          'Je lijdt aan slapeloosheid of insomnie wanneer je regelmatig moeite hebt om in slaap te vallen, in slaap te blijven, of weer in slaap te vallen nadat je is nachts wakker bent geworden. Insomnie kan je overdag slaperig maken en beïnvloedt je geheugen, concentratie, en leervermogen.',
      },
      sleep_health: {
        subtitle: 'Je resultaten suggereren dat je voordeel kan halen uit het actief proberen verbeteren van je slaapgezondheid.',
        question: 'Wat is slaapgezondheid?',
        definition:
          'Je lichaam en geest zijn afhankelijk van een goede nachtrust voor zowel je lichamelijke als geestelijke gezondheid, evenals je algemene levenskwaliteit. Terwijl we slapen, vinden er veel belangrijke processen plaats die het lichaam helpen te herstellen. Slaap ondersteunt ook de hersenfunctie en verbetert het geheugen en je stemming.',
        recommendation:
          'Een goede nachtrust helpt je beter en meer alert voelen - je voelt je meer energiek en kan je beter concentreren. Voldoende slaap krijgen is een van de belangrijkste dingen die je kan doen voor uw gezondheid en algemeen welzijn.',
      },
      snoring: {
        positional: {
          title: 'Positie-afhankelijk snurken',
          severity: 'Positie-afhankelijke snurker',
          recommendation: 'Het veranderen van je slaaphouding kan de sleutel zijn tot het verlichten van je snurken.',
          question: 'Wat is positie-afhankelijk snurken?',
          definition:
            'Positie-afhankelijk snurken is wanneer je alleen in een bepaalde positie snurkt. De meeste mensen merken dat ze meer snurken op hun rug. Wanneer je op je rug slaapt, helpt de zwaartekracht de ontspannen spieren in de mond en tong terug te vallen, waardoor je luchtwegen beperkt worden. Dat is de reden waarom sommige mensen minder snurken wanneer ze op hun zijde slapen.',
        },
        mouth: {
          title: 'Mond snurken',
          severity: 'Mond snurker',
          recommendation:
            'Iedereen is anders als het gaat om snurken. Snurken wordt meestal veroorzaakt door een vernauwde luchtweg. Er zijn verschillende opties die je kunnen helpen bij het snurken, maar als je ook een risico loopt op slaap apneu, moet je een slaaptest overwegen.',
          question: 'Wat is mond snurken?',
          definition:
            'Mondsnurkers hebben ook last van geblokkeerde neusholtes en ademen daarom door hun mond wanneer ze slapen. Dit zorgt ervoor dat de zachte weefsels in hun mond trillen en een laag rommelend geluid produceren.',
        },
        nose: {
          title: 'Neus snurken',
          severity: 'Neus snurker',
          recommendation:
            'Iedereen is anders als het gaat om snurken. Snurken wordt meestal veroorzaakt door een vernauwde luchtweg. Er zijn verschillende opties die je kunnen helpen bij het snurken, maar als je ook een risico loopt op slaap apneu, moet je een slaaptest overwegen.',
          question: 'Wat is neus snurken?',
          defintion:
            'Neus snurken treedt op wanneer iets de neusholtes blokkeert. Het kan worden veroorzaakt door dingen zoals en afwijkend tussenschot, allergieÎn, een verkoudheid en de griep.',
        },
        partner: {
          title: 'Partner snurken',
          severity: 'Partner snurkt',
          recommendation:
            'Als je je zorgen maakt over het effect dat het snurken van je partner op je slaap heeft, zijn er een paar dingen die je kunt proberen. Download een snurk app om je partner te helpen de ernst van hun snurken te begrijpen.',
          question: 'Wat is snurken?',
          definition:
            'Snurken kan een teken zijn dat je niet goed ademt tijdens je slaap. Terwijl je in slaap valt, ontspannen de spieren in je mond, tong, en keel. Snurken doet zich voor wanneer deze spieren te veel ontspannen en ze je luchtwegen gedeeltelijk blokkeren en gaan trillen.',
        },
      },
    },
  },
};
