/* eslint-disable max-len */
/* tslint:disable */
export default {
  general: {
    save: 'Save',
    yes: 'Yes',
    no: 'No',
    cancel: 'Cancel',
    submit: 'Submit',
    done: 'Done',
    loading: 'Loading',
    print: 'Print',
    update: 'Update',
    next: 'Next',
    back: 'Back',
    send: 'Send',
    email: 'Email',
    help: 'Help',
    day: 'Day',
    month: 'Month',
    year: 'Year',
  },
  months: {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',
  },
  input_labels: {
    email: 'Email',
    phone: 'Phone',
    password: 'Password',
    password_confirm: 'Confirm password',
    password_new: 'New password',
    password_old: 'Old password',
    simple: 'Simple',
    select_unit: 'Select Center',
    unit: 'Center',
    lastname: 'Last name',
    firstname: 'First name',
    birthday: 'Birthday',
    birthday_and_format: 'Birthday (YYYY-MM-DD)',
    language: 'Language',
    fulfillment: 'Fulfillment by Ectosense',
    address: 'Address',
    city: 'City',
    region: 'State/Province/Region',
    zip: 'ZIP/Postal Code',
    country: 'Country',
    offline: 'Offline test',
    nights: 'Nights',
    height: 'What is your height in cm?',
    weight: 'What is your weight in kg?',
    gender: 'What is your Gender?',
    year_born: 'Which year were you born?',
  },
  locales: {
    english: 'English',
    dutch: 'Dutch',
    french: 'French',
    portuguese: 'Portuguese',
    german: 'German',
  },
  surveys: {
    title: 'Surveys',
    all_filter_option: 'All surveys',
    responses: {
      title: 'Surveys',
      subtitle: 'Responses',
    },
    columns: {
      patient: 'Patient',
      survey: 'Survey',
      response_date: 'Response Date',
      status: 'Status',
      actions: 'Actions',
    },
  },
  survey_assessment: {
    finish: {
      title: 'Thank you',
      subtitle: 'The survey was completed successfully. Your physician will evaluate the answers and undertake the appropriate actions.',
      button: 'Continue',
    },
    questions: {
      satisfaction: 'Hi, how satisfied do you feel about your current sleep?',
      sleep_description: 'How would you describe your sleep (pick most applicable)?',
      ost_sleep_description: 'How would you describe your sleep (pick most applicable)?',
      sleep_hours: 'On average, how many hours of sleep do you get each night?',
      sleep_related_pain: 'How often have you had trouble sleeping because of pain?',
      feel_sleepy: 'Even after sleeping through the night, do you feel sleepy during the day?',
      teeth_grinding: 'Do you wake up with an aching jaw, or ever been told that you grind your teeth during sleep?',
      ost_teeth_grinding: 'Do you wake up with an aching jaw, or ever been told that you grind your teeth during sleep?',
      difficult_falling_asleep: 'Do you find it difficult to fall asleep?  How severe is this problem for you?',
      difficult_staying_asleep: 'Do you find it difficult to stay asleep throughout the night? How severe is this problem for you?',
      waking_early: 'Do you have issues waking up too early and struggle to fall back asleep? How severe is this problem for you?',
      negative_impact:
        'To what extent does sleep negatively impact your quality of life? (e.g. daytime fatigue, mood, driving performance, work performance,  concentration or memory).',
      sleep_worried: 'How worried are you about your current sleep?',
      snore_self: 'Now let’s look at your night time breathing. Have you been told you snore?',
      snore_position: 'If you can recall, which position do you usually snore in while sleeping?',
      dry_mouth: 'Do you wake up with a dry mouth?',
      ost_dry_mouth: 'Do you wake up with a dry mouth?',
      snore_partner: 'Do you sleep next to someone who snores?',
      gasping_breath: 'Do you ever wake gasping for breath?',
      hold_breath: 'Have you ever been told you hold your breath while sleeping?',
      headaches: 'Do you notice that you wake up with headaches in the morning?',
      heartburn: 'Do you experience heartburn or acid reflux, or take medication to treat it?',
      blood_pressure: 'Do you have high blood pressure or are taking medicine to treat it?',
      restless_legs: 'Do you sometimes feel that you have to move your legs to make them feel comfortable?',
      disorder_medication: 'Do you suffer from depression or a mood disorder and are currently taking medication for it?',
      copd: 'Have you ever been diagnosed with COPD, Heart Failure and or use Opioids to treat chronic pain?',
      bmi:
        'Your BMI and age can help us understand your risk factor for certain sleep disorders. Let’s calculate your BMI. Please enter your height and weight?',
      // eureka: 'Was there a "Eureka/aha!" moment or incident that motivated you into improving your sleep health?',
      motivation: 'What has been your motivation for wanting to improve your sleep?',
      gender: 'Lastly, what best describes your gender?',

      // ANZ
      key_motivation_to_improve_sleep: 'What has been your key motivation to improve your sleep issues? (You can choose multiple)',
      ost_key_motivation_to_improve_sleep: 'What has been your key motivation to improve your sleep issues? (You can choose multiple)',
      change_sleep: 'What do you want to change about your sleep? (pick only 1)',
      ost_change_sleep: 'What do you want to change about your sleep? (pick only 1)',
      discussed_sleep_issues: 'Have you ever discussed Sleep related issues with any of these? (You can choose multiple)',
      ost_discussed_sleep_issues: 'Have you ever discussed Sleep related issues with any of these? (You can choose multiple)',
      wearable_tracker: 'Do you use a wearable fitness tracker or similar health tracking device?',
      ost_wearable_tracker: 'Do you use a wearable fitness tracker or similar health tracking device?',
      average_sleep_per_night: 'On average, how many hours of sleep do you get each night?',
      ost_average_sleep_per_night: 'On average, how many hours of sleep do you get each night?',
      sleep_satisfaction: 'How satisfied do you feel about your current sleep?',
      ost_sleep_satisfaction: 'How satisfied do you feel about your current sleep?',
      sleep_symptoms: 'During your sleep, which of the following applies to you? Select all that apply.',
      ost_sleep_symptoms: 'During your sleep, which of the following applies to you? Select all that apply.',
      weekly_average_symptoms: 'On average, do you experience these symptoms more than 3 times a week?',
      ost_weekly_average_symptoms: 'On average, do you experience these symptoms more than 3 times a week?',
      past_3_months_symptoms: 'Have you experienced these symptoms for more than 3 months?',
      ost_past_3_months_symptoms: 'Have you experienced these symptoms for more than 3 months?',
      sleep_interfering_daily_activities: 'Do you feel that your sleep problems are interfering with your daily functioning? ',
      ost_sleep_interfering_daily_activities: 'Do you feel that your sleep problems are interfering with your daily functioning? ',
      is_snore: 'Have you been told you snore?',
      ost_is_snore: 'Have you been told you snore?',
      snoring_position: 'If you can recall, which position do you usually snore in while sleeping?',
      ost_snoring_position: 'If you can recall, which position do you usually snore in while sleeping?',
      snoring_partner: 'Do you sleep next to someone who snores?',
      ost_snoring_partner: 'Do you sleep next to someone who snores?',
      wake_up_headaches: 'Do you wake with headaches in the morning?',
      ost_headaches: 'Do you wake with headaches in the morning?',
      sleepy_during_day: 'Even after sleeping through the night, do you feel sleepy during the day?',
      ost_sleepy_during_day: 'Even after sleeping through the night, do you feel sleepy during the day?',
      how_sleepy: 'How sleepy do you usually feel during the day?',
      ost_how_sleepy: 'How sleepy do you usually feel during the day?',
      hold_breath_while_sleeping: 'Have you ever been told you hold your breath while sleeping? ',
      ost_hold_breath_while_sleeping: 'Have you ever been told you hold your breath while sleeping? ',
      sleep_pain: 'How often have you had trouble sleeping because of pain?',
      ost_sleep_pain: 'How often have you had trouble sleeping because of pain?',
      wake_up_coughing: 'Have you ever experienced waking up coughing? ',
      ost_wake_up_coughing: 'Have you ever experienced waking up coughing? ',
      gasping: 'Do you ever wake gasping for breath?',
      ost_gasping: 'Do you ever wake gasping for breath?',
      high_blood_pressure: 'Do you have high blood pressure or are taking medicine to treat it?',
      ost_high_blood_pressure: 'Do you have high blood pressure or are taking medicine to treat it?',
      gerd: 'Do you experience heartburn or acid reflux, or take medication to treat it?',
      ost_gerd: 'Do you experience heartburn or acid reflux, or take medication to treat it?',
      diagnosed_with_conditions: 'Have you been diagnosed with (or suffer from) any of these conditions? ',
      ost_diagnosed_with_conditions: 'Have you been diagnosed with (or suffer from) any of these conditions? ',
      move_legs_while_sleeping: 'Do you sometimes feel that you have to move your legs to make them feel comfortable?',
      ost_move_legs_while_sleeping: 'Do you sometimes feel that you have to move your legs to make them feel comfortable?',
      is_aware_sleep_apnea: 'Have you heard of a common disorder called Sleep Apnea?',
      ost_is_aware_sleep_apnea: 'Have you heard of a common disorder called Sleep Apnea?',
      sleep_apnea_related: 'Do you believe that untreated Sleep Apnea has risk on your overall health?',
      ost_sleep_apnea_related: 'Do you believe that untreated Sleep Apnea has risk on your overall health?',
      is_diagnosed_sleep_apnea: 'Have you ever been diagnosed with Sleep Apnea?',
      ost_is_diagnosed_sleep_apnea: 'Have you ever been diagnosed with Sleep Apnea?',
      ahi: 'If you recall, what was your diagnosed Apnea Hypopnea Index (AHI)?',
      ost_ahi: 'If you recall, what was your diagnosed Apnea Hypopnea Index (AHI)?',
      is_tried_cpap: 'Since your diagnosis, have you tried CPAP?',
      ost_is_tried_cpap: 'Since your diagnosis, have you tried CPAP?',
      is_using_cpap: 'Are you currently using CPAP?',
      ost_is_using_cpap: 'Are you currently using CPAP?',
      is_consent_to_forward_report: 'Do you give permission for us to send a copy of the sleep report to your GP?',
      ost_is_consent_to_forward_report: 'Do you give permission for us to send a copy of the sleep report to your GP?',
      gp_name: 'Please supply the name of your GPs and their practice name.',
      ost_gp_name: 'Please supply the name of your GPs and their practice name.',
    },
    answers: {
      yes: 'Yes',
      no: 'No',
      not_sure: 'Not sure',
      very_satisfied: 'Very satisfied',
      satisfied: 'Satisfied',
      moderately_satisfied: 'Moderately satisfied',
      dissatisfied: 'Dissatisfied',
      very_dissatisfied: 'Very dissatisfied',
      deep: 'Deep',
      great: 'Great',
      terrible: 'Terrible',
      less_5_hours: 'Less than 5 hours',
      '5_to_7_hours': '5 to 7 hours',
      '7_to_9_hours': '7 to 9 hours',
      more_9_hours: 'More than 9 hours',
      never: 'Never',
      less_once_week: 'Less than once a week',
      once_twice_week: 'Once or twice a week',
      three_more_week: 'Three or more times a week',
      no_problem: 'No problem',
      few_issues: 'Few issues',
      moderately: 'Moderately',
      severe: 'Severe',
      very_severe: 'Very severe',
      none: 'None',
      little: 'A little',
      somewhat: 'Somewhat',
      alot: 'A lot',
      not_at_all: 'Not at all',
      on_back: 'On my back',
      on_side: 'On my side',
      any_position: 'In any position',
      no_recall: "Can't recall",
      loved_one: "A loved one's suggestion",
      doctor: "Doctor's recommendation",
      energy: 'Low energy and fatigue',
      memory: 'Memory and performance issues',
      general: 'General health concerns',
      other: 'Other',
      male: 'Male',
      female: 'Female',
      light: 'Light',
      fragmented: 'Fragmented',
      interrupted: 'Could be better',

      //sleep_description

      could_be_better: "Could be better",
      disturbed: "Disturbed",

      //key_motivation_to_improve_sleep
      partner_wants_me_stop_snoring: "Partner wants me to stop snoring",
      low_energy: "Low energy and fatigue",
      ongoing_health_issue: "Ongoing health issues or risk",
      doctors_recommendation: "Doctor's recommendation",
      work_productivity: "Work productivity & performance issues",
      suddent_event: "Sudden event",
      any_other: "Any other",

      //change_sleep
      fall_asleep_faster: "Fall asleep faster",
      fall_asleep_without_medication: "Fall asleep without sleep medication",
      sleep_all_night: "Sleep all through the night",
      wake_up_earlier: "Wake up earlier",
      treat_snoring: "Treat my snoring",
      more_energy: "Have more daytime energy",
      improve_sleep_apnea: "Improve your sleep apnea treatment",

      //discussed_sleep_issues
      dr: "Doctor",
      partner: "Partner",
      friend: "Friend",
      relative: "Relative",


      //average_sleep_per_night
      "<_5_hours": 'Less than 5 hours',

      //sleep_symptoms
      difficulty_falling_asleep: "I have difficulty falling asleep",
      difficulty_staying_asleep: "I have difficulty staying asleep",
      wake_up_during_night: "I often wake up during the night",
      wake_up_earlier_than_desired: "I wake up earlier than desired",
      none_of_the_above: "None of the above",

      //sleep_interfering_daily_activities
      a_little: "A little",
      much: "Much",
      very_much: "Very much interfering",
      not_at_all_interfering: 'Not at all interfering',

      //snoring_position
      back: "On my back",
      side: "On my side",
      any: "In any position",
      cant_recall: "Can't recall",

      //how_sleepy
      extremely: 'Extremely',
      very: 'Very',
      slightly: 'Slightly',

      //sleep_pain
      less_than_once_a_week: 'Less than once a week',
      one_or_twice_a_week: 'One or Twice a week',
      three_or_more_a_week: "Three or more times a week",

      //diagnosed_with_conditions
      neuromuscular_disease: 'Neuromuscular disease',
      severe_copd: "Severe COPD",
      significant_cardiovascular_disease: 'Significant cardiovascular disease i.e. recent hospitalisation',
      chronic_pain: 'Chronic pain managed with prescription medications',
      any_condition: 'Any condition requiring the use of supplemental oxygen therapy in the home',
      'bmi_>_40': 'Morbid obesity (BMI >40)',

      //ahi
      'ahi_<_5': 'AHI <5',
      'ahi_>_5_<_15': 'AHI >5<15',
      'ahi_>_15_<_30': 'AHI >15<30',
      'ahi_>_30': 'AHI >30',
      dont_recall: `Don't recall`,

      //gp_name
      text_field: ''
    },
    sleep_health: {
      sleep_health1: {
        light: 'Light sleeper',
        interrupted: 'Interrupted sleeper',
        fragmented: 'Fragmented sleeper',
      },
      sleep_health2: {
        yes: 'Heartburn/Acid reflux',
      },
      sleep_health3: {
        yes: 'Restless legs',
      },
    },
    physio_signs: {
      snoring: 'Snoring',
      hold_breath: 'Holds breath',
      gasping: 'Gasping for breath',
      sleepy: 'Daytime sleepiness',
      dry_mouth: 'Dry mouth/Sore throat',
      heartburn: 'Heartburn/reflux',
      blood_pressure: 'High blood pressure',
      headaches: 'Headaches',
    },
    snoring_type: {
      positional: 'Positional snoring',
      mouth: 'Mouth snoring',
      nose: 'Nose snoring',
      partner: 'Partner snoring',
    },
    result: {
      patient: {
        title: 'Patient',
        name: 'Name',
        birthday: 'Birthday',
      },
      evaluation: {
        title: 'Evaluation',
      },
      indicator: 'Risk indicator:',
      your_indicators: 'Your indicators:',
      your_physio_indicators: 'Your physiological indicators:',
      recommendations: 'Care Recommendations',
      high_risk: 'high risk',
      moderate_risk: 'moderate risk',
      low_risk: 'low risk',
      at_risk: 'at risk',
      at_risk_with_pain: 'at risk of insomnia with pain',
      apnea: {
        title: 'Sleep Apnea',
        subtitle: 'Your symptoms and BMI suggests that you may be at {{severity}} of Sleep apnea.',
        question: 'What is Sleep apnea?',
        recommendations: {
          risk:
            'Your sleep assessment results indicate you may be at risk of Sleep Apnea. Consider taking a home sleep test to determine if you require treatment or talk to your doctor. Waking up feeling refreshed and energised will help you enjoy the things you love.',
          low_risk:
            "Your sleep assessment results indicate you may be at low risk of Sleep Apnea. If you're unsure about your symptoms you should consider taking a home sleep test to determine if you require treatment. Alternatively, you may want to talk to your doctor.",
          copd:
            'Your sleep assessment results indicate that due to existing medical conditions you will need to speak to your doctor about your symptoms and possible treatments options to improve your sleep.',
        },
        definition:
          'Sleep apnea is a sleep disorder that causes your body to stop breathing while you sleep. Air stops flowing to your lungs for 10 seconds or longer which can happen repeatedly during the night. Left undiagnosed and untreated, it can have long-term effects on your health. A home sleep study will help determine if you have a sleep disorder.',
      },
      insomnia: {
        title: 'Insomnia',
        risk_title: '{{risk}} of insomnia',
        risk_pain_title: '{{risk}} of insomnia with pain',
        subtitle: 'Your assessment results suggest you may be at {{severity}}.',
        question: 'What is insomnia?',
        recommendations: {
          none: 'Looks like insomnia may not be a problem for you.',
          normal: 'Your sleep assessment results indicate you may be at risk of Insomnia. You may want to talk to your doctor.',
          pain:
            'Your sleep assessment results indicate you may be at risk of insomnia due to pain. You should speak to your doctor who may refer you to a sleep psychologist. You deserve to feel your best with the energy to live each day to its full potential.',
        },
        definition:
          'Insomnia is the name given to regularly having a hard time falling asleep, staying asleep or getting back to sleep if you wake in the night. Insomnia can make you feel drowsy during the day, and effect memory, concentration and learning.',
      },
      sleep_health: {
        subtitle: 'Your assessment results suggest you may benefit from an active approach to improve your sleep health',
        question: 'What is Sleep Health',
        definition:
          'Your body and mind depend on a good night’s sleep for your physical and mental health as well as your quality of life. While we sleep many important functions take place that helps the body to repair itself. Sleep also supports brain function and improves memory and mood',
        recommendation:
          'A good night’s sleep helps you feel good and be more alert, you feel more energetic and can concentrate better. Getting enough sleep is one of the most important things you can do for your health and well-being.',
      },
      snoring: {
        positional: {
          title: 'Positional snoring',
          severity: 'Positional snorer',
          recommendation: 'Changing your sleeping position may be key to easing your snoring. You may want to talk to your doctor.',
          question: 'What is positional snoring?',
          definition:
            'Positional snoring is when you only snore in a certain position. Most people find they snore more when sleeping on their back. When you sleep on your back, gravity helps the relaxed muscles in your mouth and tongue to fall back and restrict your airway. That’s why some people snore less when they sleep on their side.',
        },
        mouth: {
          title: 'Mouth snoring',
          severity: 'Mouth snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is mouth snoring?',
          definition:
            'Mouth snorers also suffer from blocked nasal passages and therefore breathe through their mouth when they sleep. This causes the soft tissues inside their mouth to vibrate and produce a low rumbling sound.',
        },
        nose: {
          title: 'Nose snoring',
          severity: 'Nose snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is nose snoring?',
          defintion:
            'Nose snoring occurs when something is blocking the nasal passages. It can be caused by things like a deviated septum, allergies, colds and flu.',
        },
        partner: {
          title: 'Partner snoring',
          severity: 'Partner snores',
          recommendation:
            'If you’re worried about the effect that your partner’s snoring is having on your sleep there are a few things you can try. For example, you can download a snoring app to help your partner understand the severity of his or her snoring',
          question: 'What is snoring?',
          definition:
            'Snoring can be a sign that you’re not breathing properly when you sleep. As you fall asleep the muscles in your mouth, tongue and throat relax. Snoring occurs when these muscles relax too much and they partially block your airway and vibrate.',
        },
      },
    },
  },
};
