import React from 'react';
import { getScopedI18n, getSurveyLanguage } from "core/i18n";

const ta = getScopedI18n('survey_assessment.answers', { locale: getSurveyLanguage() });

interface Props {
  title: string;
  answerIndex: number;
  questionIndex: number;
  answers: string[];
  answerHandler(answer: string, question: number): void;
  className: string;
  hasFinishButton: boolean;
  handleClickFinish(): void;
}

export const MultipleChoiceQuestion: React.FC<Props> = (props: Props) => {
  return (
    <div className={props.className}>
      <div className="hs-form">
        <h5 className="question">
          <span>{props.title}</span>
        </h5>
        <div className="answer-choices diagnosis-answer-choices">
          {props.answers.map((ele, index) => {
            if (props.answerIndex >= 0 && index === props.answerIndex) {
              return (
                <button
                  type="button"
                  onClick={() => props.answerHandler(ele, props.questionIndex)}
                  className="btn btn-lg btn-primary answer"
                  key={index}
                >
                  {ta(ele)}
                </button>
              );
            } else {
              return (
                <button
                  type="button"
                  onClick={() => props.answerHandler(ele, props.questionIndex)}
                  className="btn btn-lg btn-light answer "
                  key={index}
                >
                  {ta(ele)}
                </button>
              );
            }
          })}
        </div>
        {props.hasFinishButton ? <button
          id="other-field-next-button"
          type="button"
          onClick={props.handleClickFinish}
          className="btn btn-lg btn-primary other-field-next-button"
          style={{
            display: 'flex',
            marginLeft: 'auto',
            marginTop: '1rem'
          }}
        >
          Finish
        </button> : <></>
        }
      </div>
    </div>
  );
};
