import React, { useState } from 'react';
import { ANZ_InputChoiceQuestion } from './ANZ_InputChoiceQuestion';

export const ANZ_InputQuestion = props => {
    const { rootLevelClass, questionIndex, title } = props;
    const [value, setValue] = useState('');

    const onChange = (val) => {
        setValue(val);
    }

    return (
        <div className={props.rootLevelClass} style={{ marginBottom: 0 }}>
            <div className="hs-form">
                <h5 className="question">
                    <span>{title}</span>
                </h5>
                <ANZ_InputChoiceQuestion
                    rootLevelClass={rootLevelClass}
                    onChange={onChange}
                />
            </div>
            <button
                id="other-field-next-button"
                type="button"
                disabled={value.length == 0}
                onClick={() => props.handleOnClickButton(value, questionIndex)}
                className="btn btn-lg btn-primary other-field-next-button"
                style={{
                    display: 'flex',
                    marginLeft: 'auto',
                    marginTop: '1rem'
                }}
            >
                {props.isFinalQuestion ? 'Finish' : 'Next'}
            </button>
        </div>
    );
};
