import axios from 'axios';

const SURVEY_BASE_URL = __DEV__ ? SURVEY_DEV_URL : SURVEY_URL;
const DIRECT_SURVEY_BASE_URL = __DEV__ ? DIRECT_SURVEY_DEV_URL : DIRECT_SURVEY_URL;
const SIGNING_SERVICE = 'HCP';

export const surveyApiInstance = axios.create({
  baseURL: SURVEY_BASE_URL,
  headers: {
    'x-api-key': EST,
    'x-manufacturer-id': 'browser',
    'x-signing-service': SIGNING_SERVICE,
  },
});

export const proxysurveyApiInstance = axios.create({
  baseURL: DIRECT_SURVEY_BASE_URL,
  headers: {
    'x-api-key': EST,
    'x-manufacturer-id': 'browser',
    'x-signing-service': SIGNING_SERVICE,
  },
});

export function setUser(userID: string | null): void {
  // if (token === null) {
  //   v3Api = axios.create({
  //     baseURL: V3_BASE_URL,
  //     headers: {
  //       'x-signing-service': SIGNING_SERVICE,
  //     },
  //   });
  //   nightOwlApi = axios.create({
  //     baseURL: BASE_URL,
  //   });
  // } else {
  //   nightOwlApi = axios.create({
  //     baseURL: BASE_URL,
  //     headers: {
  //       'x-nimbu-user': userID,
  //       'x-nimbu-session-token': token,
  //     },
  //   });
  //   axios.create({
  //     baseURL: V3_BASE_URL,
  //     headers: {
  //       'x-signing-service': SIGNING_SERVICE,
  //       'x-auth-token': token,
  //     },
  //   });
  // }
}
