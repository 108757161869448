import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { SurveyFinished } from 'containers/Survey/components/SurveyFinished';
import NOSurvey from './containers/Survey/assessment';
import { SurveyContainer } from './containers/Survey';

type Props = RouteComponentProps;

// /survey redirects to finish as this could indicate the survey id was undefined. Otherwise it would show the assessment survey

const App: React.FC<Props> = (props: Props) => (
  <Switch>
    <Route path="/survey/:id" component={SurveyContainer} />
    <Route path="/assessment" component={NOSurvey} />
    <Route path="/finish" component={SurveyFinished} />
    <Route path="/survey" render={() => <Redirect to="/finish" />} />
    <Route path="/surveys" component={SurveyContainer} />
    <Route path="/" render={() => <Redirect to={{ pathname: '/assessment', search: props.location.search }} />} />
  </Switch>
);

export default withRouter(App);
