import React from 'react';
import { Col, Form, FormControlProps } from 'react-bootstrap';

interface Props extends FormControlProps {
  label?: string;
  asCol?: boolean;
  groupAs?: any;
  required?: boolean;
  name?: string;
}

export const FormInput: React.FunctionComponent<Props> = ({ label, asCol, groupAs, required, ...props }: Props) => {
  return (
    <Form.Group {...{ as: asCol ? Col : groupAs ? groupAs : undefined }}>
      {label && (
        <Form.Label>
          {label}
          {required ? ' *' : null}
        </Form.Label>
      )}
      <Form.Control {...props} />
    </Form.Group>
  );
};
