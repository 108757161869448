import React from 'react';

export const ANZ_InputChoiceQuestion = props => {

    return (
        <div className={props.rootLevelClass} style={{ marginBottom: 0 }}>
            <div className="hs-form">
                <div className="answer-choices diagnosis-answer-choices cst_no_input">
                    <div className="cst_input" style={{ margin: '0px 0.3rem' }}>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            onChange={e => props.onChange(e.target.value)}
                            className="form-control"
                            placeholder="Enter your text here..."
                            style={{ margin: 0 }}
                            autoFocus
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
