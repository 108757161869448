import React, { useState } from 'react';

import DatePicker from 'react-datepicker';
import { Form, FormControlProps } from 'react-bootstrap';
import cn from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";

interface Props extends FormControlProps {
    options?: Array<Object>;
    label?: string,
    //value?: any;
    //required?: boolean;
    //isInvalid: boolean;
    //label?: string;
}

interface State {
    selectedOption: Object;
}

export const YearPicker: React.Component<Props> = ({ label, asCol, groupAs, required, options, ...props }: Props) => {

    const [year, setYear] = useState(null);

    const onYearChange = (e) => {
        setYear(e);
    }

    return <div>
        <Form.Group {...{ as: asCol ? Col : groupAs ? groupAs : undefined }}>
            {label && (
                <Form.Label>
                    {label}
                    {required ? '*' : null}
                </Form.Label>
            )}
            <DatePicker
                className="form-control"
                selected={year}
                onChange={onYearChange}
                showYearPicker
                dateFormat="yyyy"
                style={{
                    maxWidth: 250
                }}
            />

        </Form.Group >

    </div >

}