import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Spinner } from 'react-bootstrap';

import api from 'api';
import { RouteConstants } from 'containers/Survey/routes';
import { constructResponse } from 'containers/Survey/assessment/utils';
import { captureException, logScreen } from 'core/analytics';
import { ANZ_Sleep_Assessment_Questions } from '../constants/constants';
import { MultipleChoiceQuestion } from '../components/Questionaire/MultipleChoiceQuestion';
import { scrollNextQuestion, animateQuestions, animateSingleQuestions } from '../styles/custom';

import '../styles/styles.scss';
import MultipleChoiceQuestionWithOpenText from '../components/Questionaire/MultipleChoiceQuestionWithOpenText';
import SingleChoiceQuestionWithOpenText from '../components/Questionaire/SingleChoiceQuestionWithOpenText';
import { ANZ_InputQuestion } from '../components/Questionaire/ANZ_InputQuestion';


interface Props extends RouteComponentProps { }

interface State {
    questions: any[];
    submitting: boolean;
    totalQuestions: number;
    currentQuestionNumber: number;
    lastVisibleQuestion: number;
    progressPercentage: number;
}

class ANZ_Questionaire extends Component<Props, State> {
    allQuestions;

    constructor(props) {
        super(props);
        const { location } = this.props;
        const search = new URLSearchParams(location.search);
        let allQuestions = ANZ_Sleep_Assessment_Questions()
        if (search.get('country') != 'NZ') {
            allQuestions = allQuestions.slice(0, -2);
        };
        this.allQuestions = allQuestions;
        this.state = {
            submitting: false,
            questions: allQuestions,
            totalQuestions: allQuestions.length,
            currentQuestionNumber: 0,
            lastVisibleQuestion: 1,
            progressPercentage: 0,
        };
    }

    UNSAFE_componentWillMount = () => {
        const { location, history } = this.props;
        const search = new URLSearchParams(location.search);
        if (!search.get('test') && !search.get('patient')) {
            history.replace({ pathname: RouteConstants.ASSESSMENT_ROOT, search: location.search });
        }
    };

    componentDidMount = () => {
        this.setState({
            questions: [...this.state.questions]
        })
        logScreen('ASSESSMENT_SURVEY');
        animateQuestions();
        animateSingleQuestions();
        scrollNextQuestion();
    };

    onSingleChoiceAnswerHandler = (answer, index) => {
        let questions = this.state.questions.slice();
        let question = { ...questions[index] };
        if (question['selected_answer']) {
            if (question.filters) {
                // getting the original list when the filters are used, since the modified list doesn't have all the items
                let prevQuestions = this.state.questions.slice(0, index + 1);
                let subsequentQuestions = [...this.allQuestions]
                for (let counter = 0; counter < prevQuestions.length; counter++) {
                    while (prevQuestions[counter].question_key != subsequentQuestions[counter].question_key) {
                        subsequentQuestions.splice(counter, 1);
                    }
                }
                subsequentQuestions.splice(0, index + 1);
                questions = prevQuestions.concat(subsequentQuestions);
            } else {
                questions = this.state.questions.slice(0, index + 1);
                // remove selected answers for the following questions
                const temp = this.state.questions.slice(index + 1).map(ele => {
                    delete ele.selected_answer;
                    delete ele.selected_answer_index;
                    return ele;
                });
                // add it to the bottom of the question array
                questions = questions.concat(temp);
            }
            question = { ...questions[index] };
        }
        question['selected_answer'] = answer;
        question['selected_answer_index'] = question.answers.findIndex(ele => ele === (typeof answer !== 'string' ? answer.length - 1 : answer));
        questions[index] = question;

        const nextQuestion = index + 1;
        let { totalQuestions } = this.state;

        if (question.filters) {
            if (answer === question.filters.answer) {
                const { skipCounts } = question.filters;
                questions.splice(index + 1, skipCounts);
                totalQuestions = questions.length;
            }
        }

        const progressPercentage = Math.floor((nextQuestion / totalQuestions) * 100);

        if (index === questions.length - 1) {
            // end of questions
            this.setState({
                questions,
                currentQuestionNumber: nextQuestion,
                lastVisibleQuestion: nextQuestion + 1,
                progressPercentage,
                totalQuestions,
                submitQuestion: true
            });
        } else {
            this.setState({
                questions,
                currentQuestionNumber: nextQuestion,
                lastVisibleQuestion: Math.max(nextQuestion + 1, this.state.lastVisibleQuestion),
                progressPercentage,
                totalQuestions,
                submitQuestion: false
            });
        }
    };

    onMultiChoiceAnswerHandler = (answers, index, otherField) => {
        let questions = this.state.questions.slice();
        let question = { ...questions[index] };

        if (question['selected_answer']) {
            if (question.filters) {
                // getting the original list when the filters are used, since the modified list doesn't have all the items

                let prevQuestions = this.state.questions.slice(0, index + 1);
                let subsequentQuestions = [...this.allQuestions]
                for (let counter = 0; counter < prevQuestions.length; counter++) {
                    while (prevQuestions[counter].question_key != subsequentQuestions[counter].question_key) {
                        subsequentQuestions.splice(counter, 1);
                    }
                }
                subsequentQuestions.splice(0, index + 1);
                questions = prevQuestions.concat(subsequentQuestions);
            } else {
                questions = this.state.questions.slice(0, index + 1);
                // remove selected answers for the following questions
                const temp = this.state.questions.slice(index + 1).map(ele => {
                    delete ele.selected_answer;
                    delete ele.selected_answer_index;
                    return ele;
                });
                // add it to the bottom of the question array
                questions = questions.concat(temp);
            }
            question = { ...questions[index] };
        }
        question['selected_answer'] = otherField ? answers.filter(e => e !== otherField) : answers;
        question['selected_answer_index'] = []
        for (let a of answers) {
            if (typeof a !== 'string') {
                let key = Object.keys(a)[0];
                question['selected_answer_index'].push(question.answers.findIndex(ele => ele === key));
            } else {
                question['selected_answer_index'].push(question.answers.findIndex(ele => ele === a));
            }
        }
        questions[index] = question;

        const nextQuestion = index + 1;
        let { totalQuestions } = this.state;

        // skip questions
        if (question.filters) {
            if (answers.includes(question.filters.answer)) {

                const { skipCounts } = question.filters;

                questions.splice(index + 1, skipCounts);

                totalQuestions = questions.length;
            }
        }

        const progressPercentage = Math.floor((nextQuestion / totalQuestions) * 100);

        if (index === questions.length - 1) {
            // end of questions
            this.setState({
                questions,
                currentQuestionNumber: nextQuestion,
                lastVisibleQuestion: nextQuestion + 1,
                progressPercentage,
                totalQuestions,
                // submitQuestion: true
            });
            setTimeout(() => {
                this.postSubmittedData();
            }, 1000);
        } else {
            this.setState({
                questions,
                currentQuestionNumber: nextQuestion,
                lastVisibleQuestion: Math.max(nextQuestion + 1, this.state.lastVisibleQuestion),
                progressPercentage,
                totalQuestions,
            });
        }
    };

    onInputAnswerHandler = (answer, index) => {
        const { totalQuestions, lastVisibleQuestion, questions: stateQuestions } = this.state;
        let questions = stateQuestions.slice();
        let question = { ...questions[index] };
        if (question['selected_answer']) {
            questions = stateQuestions.slice(0, index + 1);
            // remove selected answers for the following questions
            const temp = stateQuestions.slice(index + 1).map(ele => {
                delete ele.selected_answer;
                delete ele.selected_answer_index;
                return ele;
            });
            // add it to the bottom of the question array
            questions = questions.concat(temp);
            question = { ...questions[index] };
        }
        question['selected_answer'] = answer;
        questions[index] = question;


        const nextQuestion = index + 1;
        const progressPercentage = Math.floor((nextQuestion / totalQuestions) * 100);

        // end of question
        if (questions.length - 1 == index) {
            this.setState({
                questions,
                currentQuestionNumber: nextQuestion,
                lastVisibleQuestion: nextQuestion + 1,
                progressPercentage,
                totalQuestions,
                submitQuestion: true
            });
            setTimeout(() => {
                this.postSubmittedData();
            }, 1000);
        }
        this.setState({
            questions,
            currentQuestionNumber: nextQuestion,
            lastVisibleQuestion: Math.max(nextQuestion + 1, lastVisibleQuestion),
            progressPercentage,
        });
    };

    postSubmittedData = async () => {
        const { questions } = this.state;
        const { location } = this.props;
        this.setState({
            submitting: true,
        });
        const search = new URLSearchParams(location.search);
        const unit = search.get('unit');
        const test = search.get('test');
        const patient = search.get('patient');
        const response = {
            ...constructResponse(questions),
            //evaluation: evaluateResults(questions),
        };
        try {
            await api.surveys.postResponse({
                survey: '643d222e05c28d55d6b4c761', // Assessment survey id
                response,
                unit: unit || '5f0070ced3210e0028eace07',
                test: test || undefined,
                patient: patient || undefined,
            });
            window.location.replace('/survey/finish');
        } catch (e) {
            captureException(e);
        }

        this.setState({
            submitting: false,
        });
    };

    validateIsClickPreviousQuestion = (index) => {
        let questions = this.state.questions.slice();
        let question = { ...questions[index + 1] };

        if (question['selected_answer']) {
            if (question.filters) {
                // getting the original list when the filters are used, since the modified list doesn't have all the items

                let a = this.state.questions.slice(0, index + 1);
                let b = [...this.allQuestions]
                for (let counter = 0; counter < a.length; counter++) {
                    while (a[counter].question_key != b[counter].question_key) {
                        b.splice(counter, 1);
                    }
                }
                b.splice(0, index + 1);
                questions = a.concat(b);
            } else {
                questions = this.state.questions.slice(0, index + 1);
                // remove selected answers for the following questions
                const temp = this.state.questions.slice(index + 1).map(ele => {
                    delete ele.selected_answer;
                    delete ele.selected_answer_index;
                    return ele;
                });
                // add it to the bottom of the question array
                questions = questions.concat(temp);
            }
        }
        let nextQuestion = index + 1;
        this.setState({ questions, currentQuestionNumber: index, lastVisibleQuestion: index + 1, progressPercentage: Math.floor((nextQuestion / this.state.totalQuestions) * 100) })
    }


    render() {
        const { questions, currentQuestionNumber, lastVisibleQuestion, submitting, progressPercentage, submitQuestion } = this.state;

        const renderedQuestions = questions.map((question, index) => {
            let rootLevelClass;
            if (index > lastVisibleQuestion) {
                rootLevelClass = 'question_container ques_list d-none intro-form-clinical';
            } else if (index <= currentQuestionNumber) {
                rootLevelClass = 'question_container ques_list active_Section intro-form-clinical';
            } else if (index <= lastVisibleQuestion) {
                rootLevelClass = 'question_container ques_list sub-active intro-form-clinical';
            }

            if (question.qType && question.qType === 'selectOpenText') {
                return <MultipleChoiceQuestionWithOpenText
                    answer={question.selected_answer}
                    answerIndex={question.selected_answer_index}
                    className={rootLevelClass}
                    key={question.question_key}
                    questionIndex={index}
                    title={question.question}
                    answers={question.answers}
                    selectedAnswer={question.selected_answer || []}
                    answerHandler={this.onMultiChoiceAnswerHandler}
                    otherField={question.otherField}
                    cancellingField={question.cancellingField}
                    validateIsClickPreviousQuestion={this.validateIsClickPreviousQuestion}
                />
            }
            if (question.qType && question.qType === 'singleSelectOpenText') {
                return <SingleChoiceQuestionWithOpenText
                    answer={question.selected_answer}
                    answerIndex={question.selected_answer_index}
                    className={rootLevelClass}
                    key={question.question_key}
                    questionIndex={index}
                    title={question.question}
                    answers={question.answers}
                    selectedAnswer={question.selected_answer}
                    answerHandler={this.onSingleChoiceAnswerHandler}
                    validateIsClickPreviousQuestion={this.validateIsClickPreviousQuestion}
                    otherField={question.otherField}
                />

            }
            if (question.qType && question.qType === 'openText') {
                return (<>
                    <ANZ_InputQuestion
                        rootLevelClass={rootLevelClass}
                        key={question.question_key}
                        questionIndex={index}
                        includeQuestion={true}
                        title={question.question}
                        hasButton={true}
                        handleOnClickButton={this.onInputAnswerHandler}
                        buttonText={"Complete"}
                        isFinalQuestion={index == questions.length - 1}
                    />
                </>
                );
            }
            return (
                <MultipleChoiceQuestion
                    answer={question.selected_answer}
                    answerIndex={question.selected_answer_index}
                    className={rootLevelClass}
                    key={question.question_key}
                    questionIndex={index}
                    title={question.question}
                    answers={question.answers}
                    answerHandler={this.onSingleChoiceAnswerHandler}
                    hasFinishButton={submitQuestion && index == questions.length - 1}
                    handleClickFinish={this.postSubmittedData}
                />
            );
        });

        return (
            <div className="assessment_questionaire main">
                <div className="questionaire_header">
                    <div className="range_slider_container">
                        <div className="range_slider_background">
                            <div className="range_slider_progress" style={{ flex: progressPercentage / 100 }} />
                        </div>
                    </div>
                </div>
                <div className="questionaire_body">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-8 col-md-6 col-lg-4">{renderedQuestions}</div>
                    </div>
                </div>
                {submitting && (
                    <div className="spinner-container">
                        <Spinner animation="border" variant="primary" />
                    </div>
                )}
            </div>
        );
    }
}

export default ANZ_Questionaire;
