import React, { Component } from 'react';
import { getScopedI18n, getSurveyLanguage } from "core/i18n";
import { ANZ_InputChoiceQuestion } from './ANZ_InputChoiceQuestion';

const ta = getScopedI18n('survey_assessment.answers', { locale: getSurveyLanguage() });

interface Props {
    className: string;
    title: string;
    answerIndex: number;
    questionIndex: number;
    answers: string[];
    otherField: string;
    key: string;
    cancellingField: string;
    answerHandler(answer: any[], question: number): void;
    validateIsClickPreviousQuestion(index: number): void;
}

interface State {
    isSelectedOther: boolean;
    clickedAnswers: string[];
    otherText: string;
    handleOnClickOther(a: string, b: string, c: string): void;
}

class MultipleChoiceQuestionWithOpenText extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            isSelectedOther: false,
            clickedAnswers: [],
            otherText: '',
        }
        this.openTextRef = React.createRef();
    }

    activateButtons = () => {
        for (let answer of this.state.clickedAnswers) {
            let fieldToSelect = document.getElementsByClassName(`${this.props.key}_${answer}`)[0]
            fieldToSelect.classList.remove('btn-light')
            fieldToSelect.classList.add('btn-primary')
        }
    }

    resetButtons = () => {
        for (let answer of this.props.answers) {
            let fieldToSelect = document.getElementsByClassName(`${this.props.key}_${answer}`)[0]
            fieldToSelect.classList.remove('btn-primary')
            fieldToSelect.classList.add('btn-light')
        }
        this.setState({ otherText: '', clickedAnswers: [], isSelectedOther: false })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if (prevProps.selectedAnswer.length && !this.props.selectedAnswer.length) {
            // focus on text field;
            this.resetButtons();
        }
    }

    handleOnClickNext = () => {
        let params = [...this.state.clickedAnswers];
        if (this.state.isSelectedOther) {
            params.push({
                [this.props.otherField]: this.state.otherText
            })
        }
        this.props.answerHandler(params, this.props.questionIndex, this.props.otherField)
    }

    selectCancellingField = (isSelect) => {
        if (this.props.cancellingField && isSelect) {
            let cancellingField = document.getElementsByClassName(`${this.props.key}_${this.props.cancellingField}`);
            cancellingField.length && cancellingField[0].classList.remove('btn-light')
            cancellingField.length && cancellingField[0].classList.add('btn-primary')
        } else if (this.props.cancellingField && !isSelect) {
            let cancellingField = document.getElementsByClassName(`${this.props.key}_${this.props.cancellingField}`);
            cancellingField.length && cancellingField[0].classList.remove('btn-primary')
            cancellingField.length && cancellingField[0].classList.add('btn-light')
        }
    }

    handleOnClickOther = (fieldName, questionIndex, otherField) => {
        this.props.validateIsClickPreviousQuestion(questionIndex);
        if (fieldName === this.props.cancellingField) {
            for (let answer of this.state.clickedAnswers) {
                let answerList = document.getElementsByClassName(`${this.props.key}_${answer}`);
                answerList.length && answerList[0].classList.add('btn-light');
                answerList.length && answerList[0].classList.remove('btn-primary');
            }
            this.selectCancellingField(true);
            this.setState({
                clickedAnswers: [fieldName],
                isSelectedOther: false,
                otherText: '',
            })
        } else if (this.state.clickedAnswers.includes(fieldName)) {
            // unselect 
            this.setState({
                clickedAnswers: this.state.clickedAnswers.filter(e => e !== fieldName),
                isSelectedOther: fieldName === otherField ? false : this.state.isSelectedOther,
                otherText: fieldName === otherField ? '' : this.state.otherText,
            }, () => {
                let fieldToUnselect = document.getElementsByClassName(`${this.props.key}_${fieldName}`)[0]
                fieldToUnselect.classList.add('btn-light')
                fieldToUnselect.classList.remove('btn-primary')

                this.activateButtons();
            })
        } else {
            this.selectCancellingField(false);

            this.setState({
                clickedAnswers: [...this.state.clickedAnswers.filter(e => e !== this.props.cancellingField), fieldName],
                isSelectedOther: fieldName === otherField ? true : this.state.isSelectedOther
            }, () => {
                this.activateButtons();
            })
        }
    }

    render() {
        const { className, title, key, answers, questionIndex, otherField } = this.props;
        return (
            <div className={`${className}-other`}>
                <div className="hs-form">
                    <h5 className="question">
                        <span>{title}</span>
                    </h5>
                    <div className="answer-choices diagnosis-answer-choices">
                        {answers.map((ele) => {
                            return (
                                <button
                                    type="button"
                                    onClick={() => this.handleOnClickOther(ele, questionIndex, otherField)}
                                    className={`btn btn-lg btn-light answer ${key}_${ele}`}
                                >
                                    {ta(ele)}
                                </button>
                            );
                        })}
                    </div>
                    {this.state.isSelectedOther && otherField ? <div>
                        <ANZ_InputChoiceQuestion
                            rootLevelClass={className}
                            key={key}
                            questionIndex={questionIndex}
                            title={title}
                            onChange={(e) => this.setState({ otherText: e })}
                        />
                    </div> : ''}
                    <button
                        id="other-field-next-button"
                        type="button"
                        disabled={this.state.clickedAnswers.length == 0 || (this.state.clickedAnswers.includes(otherField) && this.state.otherText.length == 0)}
                        onClick={this.handleOnClickNext}
                        className="btn btn-lg btn-primary other-field-next-button"
                        style={{
                            display: 'flex',
                            marginLeft: 'auto',
                            marginTop: '1rem'
                        }}
                    >
                        Next
                    </button>
                </div>

            </ div>
        );
    }
};

export default MultipleChoiceQuestionWithOpenText;