import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Spinner } from 'react-bootstrap';

import api from 'api';
import { RouteConstants } from 'containers/Survey/routes';
import { constructResponse } from 'containers/Survey/assessment/utils';
import { captureException, logScreen } from 'core/analytics';
import { Sleep_Assessment_Questions } from '../constants/constants';
import { evaluateResults } from '../utils/FindResult';
import { MultipleChoiceQuestion } from '../components/Questionaire/MultipleChoiceQuestion';
import { InputChoiceQuestion } from '../components/Questionaire/InputChoiceQuestion';
import { animateQuestions } from '../styles/custom';

import '../styles/styles.scss';

interface Props extends RouteComponentProps {}

interface State {
  questions: any[];
  submitting: boolean;
  totalQuestions: number;
  currentQuestionNumber: number;
  lastVisibleQuestion: number;
  progressPercentage: number;
}

class Questionaire extends Component<Props, State> {
  allQuestions;

  constructor(props) {
    super(props);
    const allQuestions = Sleep_Assessment_Questions();
    this.allQuestions = allQuestions;
    this.state = {
      submitting: false,
      questions: allQuestions,
      totalQuestions: allQuestions.length,
      currentQuestionNumber: 0,
      lastVisibleQuestion: 1,
      progressPercentage: 0,
    };
  }

  UNSAFE_componentWillMount = () => {
    const { location, history } = this.props;
    const search = new URLSearchParams(location.search);
    if (!search.get('test') && !search.get('patient')) {
      history.replace({ pathname: RouteConstants.ASSESSMENT_ROOT, search: location.search });
    }
  };

  componentDidMount = () => {
    logScreen('ASSESSMENT_SURVEY');
    animateQuestions();
  };

  onMultiChoiceAnswerHandler = (answer, index) => {
    let questions = this.state.questions.slice();
    let question = { ...questions[index] };
    if (question['selected_answer']) {
      if (question.filters) {
        // getting the original list when the filters are used, since the modified list doesn't have all the items
        questions = this.state.questions.slice(0, index + 1).concat(this.allQuestions.slice(index + 1));
      } else {
        questions = this.state.questions.slice(0, index + 1);
        // remove selected answers for the following questions
        const temp = this.state.questions.slice(index + 1).map(ele => {
          delete ele.selected_answer;
          delete ele.selected_answer_index;
          return ele;
        });
        // add it to the bottom of the question array
        questions = questions.concat(temp);
      }
      question = { ...questions[index] };
    }
    question['selected_answer'] = answer;
    question['selected_answer_index'] = question.answers.findIndex(ele => ele === answer);
    questions[index] = question;

    const nextQuestion = index + 1;
    let { totalQuestions } = this.state;

    if (question.filters) {
      if (answer === question.filters.answer) {
        const { skipToIndex } = question.filters;
        const diff = skipToIndex - index - 1;
        questions.splice(index + 1, diff);
        totalQuestions = questions.length;
      }
    }

    const progressPercentage = Math.floor((nextQuestion / totalQuestions) * 100);

    if (index === questions.length - 1) {
      // end of questions
      this.setState({
        questions,
        currentQuestionNumber: nextQuestion,
        lastVisibleQuestion: nextQuestion + 1,
        progressPercentage,
        totalQuestions,
        // submitQuestion: true
      });
      setTimeout(() => {
        this.postSubmittedData();
      }, 1000);
    } else {
      this.setState({
        questions,
        currentQuestionNumber: nextQuestion,
        lastVisibleQuestion: Math.max(nextQuestion + 1, this.state.lastVisibleQuestion),
        progressPercentage,
        totalQuestions,
      });
    }
  };

  postSubmittedData = async () => {
    const { questions } = this.state;
    const { location } = this.props;
    this.setState({
      submitting: true,
    });

    const search = new URLSearchParams(location.search);
    const unit = search.get('unit');
    const test = search.get('test');
    const patient = search.get('patient');
    const response = {
      ...constructResponse(questions),
      evaluation: evaluateResults(questions),
    };
    try {
      await api.surveys.postResponse({
        survey: '5de678751c9d44000007f921', // Assessment survey id
        response,
        unit,
        test: test || undefined,
        patient: patient || undefined,
      });
      window.location.replace(RouteConstants.ASSESSMENT_FINISH);
    } catch (e) {
      captureException(e);
    }

    this.setState({
      submitting: false,
    });
  };

  onInputAnswerHandler = (answers, index) => {
    const { totalQuestions, lastVisibleQuestion, questions: stateQuestions } = this.state;
    let questions = stateQuestions.slice();
    let question = { ...questions[index] };
    if (question['selected_answer']) {
      questions = stateQuestions.slice(0, index + 1);
      // remove selected answers for the following questions
      const temp = stateQuestions.slice(index + 1).map(ele => {
        delete ele.selected_answer;
        delete ele.selected_answer_index;
        return ele;
      });
      // add it to the bottom of the question array
      questions = questions.concat(temp);
      question = { ...questions[index] };
    }
    question['selected_answer'] = { weight: answers[1], height: answers[0] };
    questions[index] = question;

    const nextQuestion = index + 1;
    const progressPercentage = Math.floor((nextQuestion / totalQuestions) * 100);

    this.setState({
      questions,
      currentQuestionNumber: nextQuestion,
      lastVisibleQuestion: Math.max(nextQuestion + 1, lastVisibleQuestion),
      progressPercentage,
    });
  };

  render() {
    const { questions, currentQuestionNumber, lastVisibleQuestion, submitting, progressPercentage } = this.state;

    const renderedQuestions = questions.map((question, index) => {
      let rootLevelClass;
      if (index > lastVisibleQuestion) {
        rootLevelClass = 'question_container ques_list d-none intro-form-clinical';
      } else if (index <= currentQuestionNumber) {
        rootLevelClass = 'question_container ques_list active_Section intro-form-clinical';
      } else if (index <= lastVisibleQuestion) {
        rootLevelClass = 'question_container ques_list sub-active intro-form-clinical';
      }

      if (question.qType && question.qType === 'input') {
        return (
          <InputChoiceQuestion
            rootLevelClass={rootLevelClass}
            key={question.question_key}
            questionIndex={index}
            title={question.question}
            answerHandler={this.onInputAnswerHandler}
          />
        );
      }

      return (
        <MultipleChoiceQuestion
          answer={question.selected_answer}
          answerIndex={question.selected_answer_index}
          className={rootLevelClass}
          key={question.question_key}
          questionIndex={index}
          title={question.question}
          answers={question.answers}
          answerHandler={this.onMultiChoiceAnswerHandler}
        />
      );
    });

    return (
      <div className="assessment_questionaire main">
        <div className="questionaire_header">
          <div className="range_slider_container">
            <div className="range_slider_background">
              <div className="range_slider_progress" style={{ flex: progressPercentage / 100 }} />
            </div>
          </div>
        </div>
        <div className="questionaire_body">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6 col-lg-4">{renderedQuestions}</div>
          </div>
        </div>
        {submitting && (
          <div className="spinner-container">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
      </div>
    );
  }
}

export default Questionaire;
