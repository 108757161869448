import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { RouteConstants } from 'containers/Survey/routes';
import Home from './containers/Home';
import ANZ_Questionaire from './containers/ANZ_Questionaire';
import Result from './containers/Result';
import { Finish } from './containers/Finish';
import Questionaire from './containers/Questionaire';

const redirectLogic = props => {
  const search = new URLSearchParams(props.location.search);
  if (search.get('test') || search.get('patient')) {
    return {
      pathname: RouteConstants.ASSESSMENT_SURVEY,
      search: props.location.search,
      state: {
        user: {
          _id: search.get('patient'),
        },
      },
    };
  }

  let routerObj = { pathname: RouteConstants.ASSESSMENT_HOME, search: props.location.search };
  if (props && props.location && props.location.state && props.location.state.surveyId) {
    Object.assign(routerObj, { state: props.location.state })
  }
  return routerObj;
};

const routes = (
  <Switch>
    <Route exact path={RouteConstants.ASSESSMENT_ROOT} render={props => <Redirect to={redirectLogic(props)} />} />
    <Route path={RouteConstants.ASSESSMENT_HOME} component={Home} />
    <Route path={RouteConstants.ASSESSMENT_SURVEY} component={Questionaire} />
    <Route path={RouteConstants.ASSESSMENT_SURVEY_ANZ} component={ANZ_Questionaire} />
    <Route path={RouteConstants.ASSESSMENT_FINISH} component={Finish} />
    <Route path={RouteConstants.ASSESSMENT_RESULT} component={Result} />
  </Switch>
);

const App: React.FC = () => <>{routes}</>;

export default App;
