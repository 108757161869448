import { keyBy } from 'lodash';
import { INSOMNIA_RISK_TYPE, OSA_RISK_TYPE, QuestionType, SLEEP_HEALTH, SNORING_TYPE } from '../constants/constants';

const getOsaRisk = groupedQuestions => {
  const band1QsCount = groupedQuestions.band1.filter(question => {
    return question.selected_answer && question.selected_answer === 'yes';
  }).length;

  const band2QsCount = groupedQuestions.band2.filter(question => {
    return typeof question.selected_answer === 'string' && question.selected_answer && question.selected_answer === 'yes';
  }).length;

  // Yes ot 6 to 8 (Band 1 and 2)
  if (band1QsCount + band2QsCount >= 6) {
    return OSA_RISK_TYPE.OSAHighRisk;
  }

  // Yes to 3 or 4 from Band 1
  if (band1QsCount >= 3) {
    return OSA_RISK_TYPE.OSAHighRisk;
  }

  // Yes to 2 from Band 1 and Male
  if (band1QsCount >= 2 && groupedQuestions.gender.selected_answer === 'male') {
    return OSA_RISK_TYPE.OSAHighRisk;
  }

  // Yes to 2 Band 1 questions and BMI > 35kg/m^2
  const { weight } = groupedQuestions.bmi.selected_answer; // kg
  const { height } = groupedQuestions.bmi.selected_answer; // centimeter

  const calculatedBMI = weight / (height / 100) ** 2;
  const bmiThreshold = 35.0;

  // Yes to 2 from Band 1 and BMI > 35
  if (band1QsCount >= 2 && calculatedBMI > bmiThreshold) {
    return OSA_RISK_TYPE.OSAHighRisk;
  }

  if (band1QsCount + band2QsCount >= 3) {
    return OSA_RISK_TYPE.OSAAtRisk;
  }

  return OSA_RISK_TYPE.OSALowRisk;
};

const getSnoringRisk = groupedQuestions => {
  if (groupedQuestions.snoring.length > 1) {
    const keyedQuestions = keyBy(groupedQuestions.snoring, q => q.question_key);
    const snoringSignsRisks = [];

    if (keyedQuestions.snore_self && keyedQuestions.snore_self.selected_answer === 'yes') {
      // positional Snoring
      let weightage = keyedQuestions.snore_position.answersWeight[keyedQuestions.snore_position.selected_answer];
      if (weightage === 'back' || weightage === 'side') {
        snoringSignsRisks.push(SNORING_TYPE.positional);
      } else {
        weightage = keyedQuestions.dry_mouth.answersWeight[keyedQuestions.dry_mouth.selected_answer];
        if (weightage === 'mouth' && keyedQuestions.teeth_grinding.selected_answer === 'no') {
          snoringSignsRisks.push(SNORING_TYPE.mouth);
        } else if (weightage === 'nose') {
          snoringSignsRisks.push(SNORING_TYPE.nose);
        }
      }

      if (keyedQuestions.snore_partner.selected_answer === 'yes') {
        snoringSignsRisks.push(SNORING_TYPE.partner);
      }
    } else if (keyedQuestions.snore_position && keyedQuestions.snore_position.selected_answer === 'yes') {
      snoringSignsRisks.push(SNORING_TYPE.partner);
    }
    return snoringSignsRisks;
  }
  return null;
};

const getInsomniaScore = groupedQuestions => {
  let score = 0;
  let pains = 0;
  groupedQuestions.insomnia.forEach(question => {
    const answer = question.selected_answer;
    const value = question.answersWeight[answer];
    if (!isNaN(value)) {
      score += value;
    } else if (value && value.toLowerCase() === 'pain') {
      pains += 1;
    }
  });

  let risk = INSOMNIA_RISK_TYPE.LowRisk;
  // Risk of Insomnia and pain
  // if (insomniaScore === 13 && pains > 0){
  // 	insomniaSeverityType = InsomniaRiskType.InsomniaAndPain;
  // }else
  if (score >= 18) {
    risk = INSOMNIA_RISK_TYPE.HighRisk;
  } else if (score >= 13 && score <= 18) {
    risk = INSOMNIA_RISK_TYPE.ModerateRisk;
  } else if (score >= 8 && score <= 12) {
    risk = INSOMNIA_RISK_TYPE.AtRisk;
  } else {
    risk = INSOMNIA_RISK_TYPE.LowRisk;
  }

  return { score, pains, risk };
};

const getSleepHealthScore = groupedQuestions => {
  const recommendation = {};
  groupedQuestions.sleepHealth.forEach(question => {
    const answer = question.selected_answer;
    if (question.key === SLEEP_HEALTH.key_1.value) {
      if (
        answer === SLEEP_HEALTH.key_1.answers.light ||
        answer === SLEEP_HEALTH.key_1.answers.interrupted ||
        answer === SLEEP_HEALTH.key_1.answers.fragmented
      ) {
        recommendation[question.key] = answer;
      }
    }

    if (question.key === SLEEP_HEALTH.key_2.value && answer === SLEEP_HEALTH.key_2.answers.yes) {
      recommendation[question.key] = answer;
    }

    if (question.key === SLEEP_HEALTH.key_3.value && answer === SLEEP_HEALTH.key_3.answers.yes) {
      recommendation[question.key] = answer;
    }
  });

  return recommendation;
};

export const evaluateResults = questions => {
  const groupedQuestions = {
    band1: [],
    band2: [],
    snoring: [],
    insomnia: [],
    sleepHealth: [],
    gender: null,
    bmi: null,
  };

  const physioSigns = [];
  questions.forEach(question => {
    if (question.type) {
      // OSA
      if (question.type.includes(QuestionType.OSA.value)) {
        if (question.sub_type === QuestionType.OSA.bands.band1) {
          groupedQuestions.band1.push(question);
        } else if (question.sub_type === QuestionType.OSA.bands.band2) {
          groupedQuestions.band2.push(question);
        }
      }

      // Gender
      if (question.type.includes(QuestionType.gender.value)) {
        groupedQuestions.gender = question;
      }

      // BMI questions
      if (question.type.includes(QuestionType.weight.value) && question.type.includes(QuestionType.height.value)) {
        groupedQuestions.bmi = question;
      }

      // Sleep Health questions
      if (question.type.includes(QuestionType.sleepHealth.value)) {
        groupedQuestions.sleepHealth.push(question);
      }

      // Snoring questions
      if (question.type.includes(QuestionType.snoring.value)) {
        groupedQuestions.snoring.push(question);
      }

      // Insomnia questions
      if (question.type.includes(QuestionType.insomnia.value)) {
        groupedQuestions.insomnia.push(question);
      }

      // PhysiologicalSignsRisks
      if (question.physioSign && question.selected_answer && question.selected_answer === 'yes') {
        physioSigns.push(question.physioSign);
      }
    }
  });

  return {
    osa_risk: getOsaRisk(groupedQuestions),
    snoring_risks: getSnoringRisk(groupedQuestions),
    insomnia_scoring: getInsomniaScore(groupedQuestions),
    sleep_health_recommendations: getSleepHealthScore(groupedQuestions),
    physio_signs: physioSigns,
  };
};
