import $ from 'jquery';

export function animateQuestions() {
  $(document).on('click', '.answer', function () {
    const parent = $(this).closest('.intro-form-clinical');
    const base = $(parent).next();
    if ($(base).offset()) {
      $('html,body').animate({ scrollTop: $(base).offset().top - 100 }, 'slow');
    }
  });
}

export function animateSingleQuestions() {
  $(document).on('click', '.answer-next', function () {
    const parent = $(this).closest('.intro-form-clinical-other');
    const base = $(parent).next();
    if ($(base).offset()) {
      $('html,body').animate({ scrollTop: $(base).offset().top - 100 }, 'slow');
    }
  });
}

export function scrollNextQuestion() {
  $(document).on('click', '#other-field-next-button', function () {
    const parent = $(this).closest(`.intro-form-clinical-other`);
    const base = $(parent).next();
    if ($(base).offset()) {
      $('html,body').animate({ scrollTop: $(base).offset().top - 100 }, 'slow');
    }
  });
}

export function addTabScript() {
  $('document').ready(function () {
    $('.results_tab').hide();
    $('.results_tab:first').show();
  });

  $('.tab-slider-nav li').click(function () {
    $('.results_tab').hide();
    var activeTab = $(this).attr('rel');
    $('#' + activeTab).fadeIn();
    if ($(this).attr('rel') == 'tab2') {
      $('.tab-slider-tabs').addClass('slide');
    } else {
      $('.tab-slider-tabs').removeClass('slide');
    }
    $('.tab-slider-nav li').removeClass('active');
    $(this).addClass('active');
  });
}

export function addCardToggleScript() {
  $('.accordion li h6').click(function () {
    var current_li = $(this).parent();
    $('.accordion li .contents').each(function (i, el) {
      if (
        $(el)
          .parent()
          .is(current_li)
      ) {
        $(el)
          .prev()
          .toggleClass('plus');
        $(el).slideToggle();
      }
      // else {
      // 	$(el)
      // 		.prev()
      // 		.removeClass('plus');
      // 	$(el).slideUp();
      // }
    });
  });
  // $('.accordion li > .contents').hide();
  //    $('.accordion li h6').first().addClass("plus");
  //    $('.accordion li > .contents').first().show().addClass("plus");

  //    $('.accordion li:nth-child(3) h6').first().addClass("plus");
  //    $('.accordion li:nth-child(3) > .contents').first().show().addClass("plus");

  $('.indicators h6').addClass('plus');
  $('.indicators > .contents')
    .show()
    .addClass('plus');

  $('.care_recommendations h6').addClass('plus');
  $('.care_recommendations > .contents')
    .show()
    .addClass('plus');

  //    $('.accordion li h6').last().addClass("plus");
  //    $('.accordion li > .contents').last().show().addClass("plus");
}
