import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Spinner } from 'react-bootstrap';

import { FormInput } from 'core/components/FormInput';
import DatePicker from 'react-datepicker';
import { BasicDropdown } from 'core/components/Dropdown';

import './UserForm_ANZ.scss';
import { getScopedI18n, getSurveyLanguage } from "core/i18n";
import { YearPicker } from 'core/components/YearPicker/YearPicker';

const tl = getScopedI18n('input_labels', { locale: getSurveyLanguage() });

const validationSchema = yup.object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup
        .string()
        .email()
        .required(),
    height: yup.string().required(),
    weight: yup.string().required(),
});

interface Props {
    submitting: boolean;
    onSubmit(values: any): void;
}

export const UserForm_ANZ: React.FC<Props> = ({ onSubmit, submitting }: Props) => {
    const options = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Unknown', label: 'Prefer not to answer' }
    ]
    return (
        <Formik onSubmit={onSubmit} initialValues={{}} validationSchema={validationSchema}>
            {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <FormInput
                        name="first_name"
                        value={values.first_name}
                        isInvalid={!!errors.first_name}
                        onChange={handleChange}
                        label={`${tl('firstname')}*`}
                    />
                    <FormInput name="last_name" value={values.last_name} isInvalid={!!errors.last_name} onChange={handleChange} label={`${tl('lastname')}*`} />
                    <FormInput name="email" type="email" value={values.email} isInvalid={!!errors.email} onChange={handleChange} label={`${tl('email')}*`} />
                    <FormInput name="height" type="height" value={values.height} isInvalid={!!errors.height} onChange={handleChange} label={`${tl('height')}*`} />
                    <FormInput name="weight" type="weight" value={values.weight} isInvalid={!!errors.weight} onChange={handleChange} label={`${tl('weight')}*`} />
                    <BasicDropdown name="gender" isInvalid={!!errors.gender} options={options} label={'What is your Gender?'} required={true} />
                    <YearPicker label="What year were you born?" required={true} />

                    {/* <BirthdayInputControlled label={`${tl('birthday')}*`} name="dob" value={values.dob} isInvalid={!!errors.dob} onChange={handleChange} /> */}
                    <div className="app_btn_dv">
                        <button type="submit" className="btn btn-primary btn-lg px-5 mt-4" disabled={submitting}>
                            {submitting ? <Spinner animation="border" variant="light" /> : 'Start'}
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
};
