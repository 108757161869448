/* tslint:disable */
export default {
  survey_assessment: {
    questions: {
      satisfaction: 'Hi, how satisfied do you feel about your current sleep?',
      sleep_description: 'How would you describe your sleep (pick most applicable)?',
      sleep_hours: 'On average, how many hours of sleep do you get each night?',
      sleep_related_pain: 'How often have you had trouble sleeping because of pain?',
      feel_sleepy: 'Even after sleeping through the night, do you feel sleepy during the day?',
      teeth_grinding: 'Do you wake up with an aching jaw, or ever been told that you grind your teeth during sleep?',
      difficult_falling_asleep: 'Do you find it difficult to fall asleep?  How severe is this problem for you?',
      difficult_staying_asleep: 'Do you find it difficult to stay asleep throughout the night? How severe is this problem for you?',
      waking_early: 'Do you have issues waking up too early and struggle to fall back asleep? How severe is this problem for you?',
      negative_impact:
        'To what extent does sleep negatively impact your quality of life? (e.g. daytime fatigue, mood, driving performance, work performance,  concentration or memory).',
      sleep_worried: 'How worried are you about your current sleep?',
      snore_self: 'Now let’s look at your night time breathing. Have you been told you snore?',
      snore_position: 'If you can recall, which position do you usually snore in while sleeping?',
      dry_mouth: 'Do you wake up with a dry mouth?',
      snore_partner: 'Do you sleep next to someone who snores?',
      gasping_breath: 'Do you ever wake gasping for breath?',
      hold_breath: 'Have you ever been told you hold your breath while sleeping?',
      headaches: 'Do you notice that you wake up with headaches in the morning?',
      heartburn: 'Do you experience heartburn or acid reflux, or take medication to treat it?',
      blood_pressure: 'Do you have high blood pressure or are taking medicine to treat it?',
      restless_legs: 'Do you sometimes feel that you have to move your legs to make them feel comfortable?',
      disorder_medication: 'Do you suffer from depression or a mood disorder and are currently taking medication for it?',
      copd: 'Have you ever been diagnosed with COPD, Heart Failure and or use Opioids to treat chronic pain?',
      bmi:
        'Your BMI and age can help us understand your risk factor for certain sleep disorders. Let’s calculate your BMI. Please enter your height and weight?',
      age: 'Thanks. Please indicate your age bracket',
      // eureka: 'Was there a "Eureka/aha!" moment or incident that motivated you into improving your sleep health?',
      motivation: 'What has been your motivation for wanting to improve your sleep?',
      gender: 'Lastly, what best describes your gender?',
    },
    answers: {
      yes: 'Yes',
      no: 'No',
      not_sure: 'Not sure',
      very_satisfied: 'Very satisfied',
      satisfied: 'Satisfied',
      moderately_satisfied: 'Moderately satisfied',
      dissatisfied: 'Dissatisfied',
      very_dissatisfied: 'Very dissatisfied',
      deep: 'Deep',
      great: 'Great',
      terrible: 'Terrible',
      less_5_hours: 'Less than 5 hours',
      '5_to_7_hours': '5 to 7 hours',
      '7_to_9_hours': '7 to 9 hours',
      more_9_hours: 'More than 9 hours',
      never: 'Never',
      less_once_week: 'Less than once a week',
      once_twice_week: 'Once or twice a week',
      three_more_week: 'Three or more times a week',
      no_problem: 'No problem',
      few_issues: 'Few issues',
      moderately: 'Moderately',
      severe: 'Severe',
      very_severe: 'Very severe',
      none: 'None',
      little: 'A little',
      somewhat: 'Somewhat',
      alot: 'A lot',
      not_at_all: 'Not at all',
      on_back: 'On my back',
      on_side: 'On my side',
      any_position: 'In any position',
      no_recall: "Can't recall",
      loved_one: "A loved one's suggestion",
      doctor: "Doctor's recommendation",
      energy: 'Low energy and fatigue',
      memory: 'Memory and performance issues',
      general: 'General health concerns',
      other: 'Other',
      male: 'Male',
      female: 'Female',
      light: 'Light',
      fragmented: 'Fragmented',
      interrupted: 'Could be better',
    },
    sleep_health: {
      sleep_health1: {
        light: 'Light sleeper',
        interrupted: 'Interrupted sleeper',
        fragmented: 'Fragmented sleeper',
      },
      sleep_health2: {
        yes: 'Heartburn/Acid reflux',
      },
      sleep_health3: {
        yes: 'Restless legs',
      },
    },
    physio_signs: {
      snoring: 'Snoring',
      hold_breath: 'Holds breath',
      gasping: 'Gasping for breath',
      sleepy: 'Daytime sleepiness',
      dry_mouth: 'Dry mouth/Sore throat',
      heartburn: 'Heartburn/reflux',
      blood_pressure: 'High blood pressure',
      headaches: 'Headaches',
    },
    snoring_type: {
      positional: 'Positional snoring',
      mouth: 'Mouth snoring',
      nose: 'Nose snoring',
      partner: 'Partner snoring',
    },
    result: {
      indicator: 'Risk indicator:',
      your_indicators: 'Your indicators:',
      your_physio_indicators: 'Your physiological indicators:',
      recommendations: 'Care Recommendations',
      high_risk: 'high risk',
      moderate_risk: 'moderate risk',
      low_risk: 'low risk',
      at_risk: 'at risk',
      at_risk_with_pain: 'at risk of insomnia with pain',
      apnea: {
        title: 'Sleep Apnea',
        subtitle: 'Your symptoms and BMI suggests that you may be at {{severity}} of Sleep apnea.',
        question: 'What is Sleep apnea',
        recommendations: {
          risk:
            "Your sleep assessment results indicate you may be at risk of Sleep Apnea. Consider taking a home sleep test to determine if you require treatment or talk to your doctor. We've also pulled together some suggested products just for you.  Waking up feeling refreshed and energised will help you enjoy the things you love. Alternatively, you may want to talk to your doctor.",
          low_risk:
            "Your sleep assessment results indicate you may be at low risk of Sleep Apnea. If you're unsure about your symptoms you should consider taking a home sleep test to determine if you require treatment. Alternatively, you may want to talk to your doctor.",
          copd:
            'Your sleep assessment results indicate that due to existing medical conditions you will need to speak to your GP about your symptoms and possible treatments options to improve your sleep. Alternatively, you may want to talk to your doctor.',
        },
        definition:
          'Sleep apnea is a sleep disorder that causes your body to stop breathing while you sleep. Air stops flowing to your lungs for 10 seconds or longer which can happen repeatedly during the night. Left undiagnosed and untreated, it can have long-term effects on your health. A home sleep study will help determine if you have a sleep disorder.',
      },
      insomnia: {
        title: 'Insomnia',
        risk_title: '{{risk}} of insomnia',
        risk_pain_title: '{{risk}} of insomnia with pain',
        subtitle: 'Your assessment results suggest you may be at {{severity}}.',
        question: 'What is insomnia',
        recommendations: {
          none:
            "Looks like insomnia may not be a problem for you. However, if you're looking for ways to improve your overall sleep health we've pulled together some suggested products just for you.  You deserve to feel your best with the energy to live each day to its full potential. Alternatively, you may want to talk to your doctor.",
          normal:
            "Your sleep assessment results indicate you may be at risk of Insomnia. We've pulled together some suggested products just for you that may help you get a better nights sleep. You deserve to feel your best with the energy to live each day to its full potential. Alternatively, you may want to talk to your doctor.",
          pain:
            'Your sleep assessment results indicate you may be at risk of insomnia due to pain. You should speak to your GP who may refer you to a sleep psychologist. You deserve to feel your best with the energy to live each day to its full potential.',
        },
        definition:
          'Insomnia is the name given to regularly having a hard time falling asleep, staying asleep or getting back to sleep if you wake in the night. Insomnia can make you feel drowsy during the day, and effect memory, concentration and learning.',
      },
      sleep_health: {
        subtitle: 'Your assessment results suggest you may benefit from sleep Health products',
        question: 'What is Sleep Health',
        definition:
          'Your body and mind depend on a good night’s sleep for your physical and mental health as well as your quality of life. While we sleep many important functions take place that helps the body to repair itself. Sleep also supports brain function and improves memory and mood',
        recommendation:
          'A good night’s sleep helps you feel good and be more alert, you feel more energetic and can concentrate better. Getting enough sleep is one of the most important things you can do for your health and well-being.  Here are some products just for you that may help improve your sleep. Alternatively, you may want to talk to your doctor.',
      },
      snoring: {
        positional: {
          title: 'Positional snoring',
          severity: 'Positional snorer',
          recommendation:
            'Changing your sleeping position may be key to easing your snoring. If you need a little extra help, here are some products that may help. Alternatively, you may want to talk to your doctor.',
          question: 'What is positional snoring?',
          definition:
            'Positional snoring is when you only snore in a certain position. Most people find they snore more when sleeping on their back. When you sleep on your back, gravity helps the relaxed muscles in your mouth and tongue to fall back and restrict your airway. That’s why some people snore less when they sleep on their side.',
        },
        mouth: {
          title: 'Mouth snoring',
          severity: 'Mouth snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is mouth snoring?',
          definition:
            'Mouth snorers also suffer from blocked nasal passages and therefore breathe through their mouth when they sleep. This causes the soft tissues inside their mouth to vibrate and produce a low rumbling sound.',
        },
        nose: {
          title: 'Nose snoring',
          severity: 'Nose snorer',
          recommendation:
            "Everyone's different when it comes to snoring. Snoring is usually caused by a narrowed airway. There are several options to try for snoring however if you are also at risk of Sleep apnea you should consider a home sleep test. Alternatively, you may want to talk to your doctor.",
          question: 'What is nose snoring?',
          defintion:
            'Nose snoring occurs when something is blocking the nasal passages. It can be caused by things like a deviated septum, allergies, colds and flu.',
        },
        partner: {
          title: 'Partner snoring',
          severity: 'Partner snores',
          recommendation:
            'If you’re worried about the effect that your partner’s snoring is having on your sleep there are a few things you can try. Download the myNight app to help them understand the severity of their snoring. Alternatively, you may want to talk to your doctor.',
          question: 'What is snoring?',
          definition:
            'Snoring can be a sign that you’re not breathing properly when you sleep. As you fall asleep the muscles in your mouth, tongue and throat relax. Snoring occurs when these muscles relax too much and they partially block your airway and vibrate.',
        },
      },
    },
  },
};
