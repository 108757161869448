import * as Sentry from '@sentry/browser';
import Amplitude from 'amplitude-js';

let userID: null | string = null;
let userProperties: { [key: string]: string | boolean | number } = {};

export function initAnalytics() {
  if (!__DEV__) {
    Sentry.init({
      dsn: 'https://ec68f521059f4658a4a7d30d03f5cc56@sentry.io/1865493',
      release: `${HOST_ENV}@${HOST_ENV === 'survey-portal-prod' ? VERSION : COMMIT_HASH}`,
      environment: HOST_ENV,
    });
    Sentry.configureScope(scope => {
      scope.setTag('version', VERSION);
    });

    Amplitude.init(AMPLITUDE_TOKEN, null, { platform: __DEV__ ? 'Web_DEV' : 'Web' });
  }
}

export function userLogout(): void {
  userID = null;
  userProperties = {};
  Amplitude.setUserId('');
  Sentry.configureScope(scope => {
    scope.setUser({ id: undefined });
  });
}

export function setUser(id: string, userProps: { [key: string]: boolean | number | string } = {}): void {
  userID = id;
  Amplitude.setUserId(id);
  Sentry.configureScope(scope => {
    scope.setUser({ id });
  });
  if (id) {
    userProperties = userProps;
  }
}

export function setUserProperties(props: { [key: string]: number | boolean | string }) {
  userProperties = {
    ...userProperties,
    ...props,
  };
}

export function logEvent(eventName: string, eventProperties?: object, timestamp?: string) {
  if (timestamp) {
    Amplitude.logEventWithTimestamp(eventName, parseInt(timestamp.valueOf(), 10), eventProperties);
  } else {
    Amplitude.logEvent(eventName, eventProperties);
  }
}

export function logScreen(screenName: string, eventProperties?: object) {
  Amplitude.logEvent(`SCREEN_${screenName.toUpperCase()}`, eventProperties);
}

export function captureException(exception: any) {
  console.error(exception);
  Sentry.captureException(exception);
}
