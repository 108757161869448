import React from 'react';
import { Container } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import * as SurveyReact from 'survey-react';
import cn from 'classnames';

import { getSurvey, postResponse } from 'api/surveys.api';
import { getBrowserLanguage } from 'core/i18n';
import { captureException, logScreen } from 'core/analytics';

type Props = RouteComponentProps<{ id: id }>;

class SurveyClass extends React.Component<Props, {}> {
  state = {
    survey: null,
    loading: true,
  };

  componentDidMount() {
    const { match } = this.props;
    const surveyId = match.params.id;
    logScreen('SURVEY', { survey: surveyId });
    if (surveyId) {
      getSurvey(surveyId)
        .then(survey => {
          this.setState({ survey });
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  }

  sendDataToServer = (survey: any) => {
    const { location } = this.props;
    const { survey: stateSurvey } = this.state;
    const search = new URLSearchParams(location.search);
    const test = search.get('test');
    const recording = search.get('recording');
    const unit = search.get('unit');
    return postResponse({
      survey: stateSurvey._id,
      response: survey.data,
      unit,
      test,
      recording,
    }).finally(() => {
      // Navigate to finished url
      window.location.replace('/finish');
    });
  };

  getLocale() {
    const { location } = this.props;
    const search = new URLSearchParams(location.search);
    const locale = search.get('locale');
    if (locale && ['en', 'nl'].includes(locale)) {
      return locale;
    } else {
      getBrowserLanguage();
    }
  }

  render() {
    const { loading, survey } = this.state;
    if (loading) {
      return <div />;
    }

    const { match, location } = this.props;
    const search = new URLSearchParams(location.search);
    const test = search.get('test');
    const unit = search.get('unit');
    const recording = search.get('recording');
    const locale = search.get('locale');
    const service = search.get('service');
    // if (!survey) {
    //   captureException(
    //     new Error(
    //       `No survey found for survey: ${match.params.id}, unit: ${unit}, test: ${test}, recording: ${recording}, locale: ${locale}`,
    //     ),
    //   );
    //   // Use window.location, otherwise app does not see change
    //   window.location.replace('/finish');
    // }

    if (service !== 'sisu') {
      SurveyReact.StylesManager.applyTheme('bootstrap');
    }

    return (
      <>
        {service === 'sisu' ? (
          <Container className={cn({ 'sisu-theme': service === 'sisu' ? true : false })} style={{ padding: 0 }}>
            {service === 'sisu' && (
              <div className="custom-sisu-container">
                <div className="img-container">
                  <img src={require('../../../assets/images/survey-icon.svg')} alt="phone" />
                </div>
              </div>
            )}
            <SurveyReact.Survey json={survey.survey} onComplete={this.sendDataToServer} locale={this.getLocale()} />
          </Container>
        ) : (
          <Container className={cn({ 'resmed-theme': RESMED_BRANDED_UNITS.includes(unit) })}>
            <SurveyReact.Survey json={survey.survey} onComplete={this.sendDataToServer} locale={this.getLocale()} />
          </Container>
        )}
      </>
    );
  }
}

export const Survey = withRouter(SurveyClass);
