import React from "react";
import Lottie from "react-lottie";

interface Props {
  title: string;
  physiologicalSignsRisks: string[];
  sleepHealthRecommendations: string[];
}

export const ResultCard: React.FC<Props> = (props: Props) => {
  let indicatorsContent = null;
  if (props.physiologicalSignsRisks && props.physiologicalSignsRisks.length > 0) {
    indicatorsContent = (
      <li className='indicators'>
        <h6 className='theme_catg accordion_title'>Your physiological indicators:</h6>
        <div className='contents'>
          <p className='theme_sm_para' />
          <ul className='physiological_signs_block'>
            {props.physiologicalSignsRisks.map((sign, index) => {
              return <li key={sign}><img src='/images/icon/tick.png' alt=''/><span>{sign}</span></li>;
            })}
          </ul>
        </div>
      </li>
    );
  } else if (props.sleepHealthRecommendations && props.sleepHealthRecommendations.length > 0) {
    indicatorsContent = (
      <li className='indicators'>
        <h6 className='theme_catg accordion_title'>Your indicators:</h6>
        <div className='contents'>
          <p className='theme_sm_para' />
          <ul className='physiological_signs_block'>
            {props.sleepHealthRecommendations.map((sign, index) => {
              return <li key={sign}><img src='/images/icon/tick.png' alt=''/><span>{sign}</span></li>;
            })}
          </ul>
        </div>
      </li>
    );
  }

  const questionsContent = [
    <li>
      <h6 className='theme_catg accordion_title'>{props.question}</h6>
      <div className='contents'>
        <p className='theme_sm_para'>{props.definition}</p>
        {/* <div class="btn_block"> */}
        {/*  <a href={props.definition_link} class="theme_btn cst_primary_btn" target="blank">Learn more</a> */}
        {/* </div> */}
      </div>
    </li>
  ];

  if (props.isSecondDefinition) {
    questionsContent.push(<li>
      <h6 className='theme_catg accordion_title'>{props.question2}</h6>
      <div className='contents'>
        <p className='theme_sm_para'>{props.definition2}</p>
        {/* <div class="btn_block"> */}
        {/*  <a href={props.primary_link2} class="theme_btn cst_primary_btn" target="blank">{props.primary_link_text2}</a> */}
        {/* </div> */}
      </div>
    </li>);
  }

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: props.icon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div className='col-3 col-md-6 col-sm-12'>
      <div className='result_card'>
        <p>Symptom matches:</p>
        <h4 className='title'>{props.title}</h4>
        <div className=''>
          {
            props.indicator_severity ?
              <div className='severity_ring_container text-center'>
                <div className='result_text severity_ring_text'>
                  <h5 className='theme_fine_print'>Risk indicator:</h5>
                  <h4 className='theme_title4'>{props.severity_title}</h4>
                </div>
                <Lottie width='80%' options={defaultOptions}/>
              </div>
              :
              <div className='result_box_img'>
                <img src={props.icon} alt=''/>
              </div>
          }
          <div className='app_text based_symptoms_txt'>
            {props.severityTitle}
          </div>
        </div>
        <ul>
          {indicatorsContent}
          {questionsContent}
          <li className='care_recommendations'>
            <h6 className='theme_catg accordion_title'>Care Recommendations</h6>
            <div className='contents'>
              <p className='theme_sm_para'>{props.care_recommendation}</p>
              {/* <div class="btn_block"> */}
              {/*  { */}
              {/*    props.primary_link ? */}
              {/*      <a href={props.primary_link} class="theme_btn cst_primary_btn" */}
              {/*         target="blank">{props.primary_link_text}</a> : null */}
              {/*  } */}
              {/*  { */}
              {/*    props.secondary_link ? */}
              {/*      <a href={props.secondary_link} class="theme_btn theme_btn_outline" */}
              {/*         target="blank">{props.secondary_link_text}</a> */}
              {/*      : null */}
              {/*  } */}
              {/* </div> */}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
